import React, {useEffect, useState} from 'react';
import {CreateChannelProvider} from "@sendbird/uikit-react/CreateChannel/context";
import {sendbirdSelectors} from "@sendbird/uikit-react";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import CustomCreateChannel from "../CustomCreateChannel/CustomCreateChannel";
import {useDebounce} from "../../Shared/hooks/useDebounce";

const CustomCreateChannelWrapper = () => {
    const [search, setSearch] = useState('')
    const [query, setQuery] = useState(null)
    const context = useSendbirdStateContext()
    const sdk = sendbirdSelectors.getSdk(context);

    const debounceString = useDebounce(search, 500)

    const [isRerender, setIsRerender] = useState(false)

    useEffect(() => {
        setQuery(sdk && sdk.createApplicationUserListQuery && sdk.createApplicationUserListQuery({
            nicknameStartsWithFilter: debounceString
        }))
        setIsRerender(true)
    }, [debounceString, sdk])


    return (
        <CreateChannelProvider
            userListQuery={() => query}
        >
            <CustomCreateChannel
                search={search}
                setSearch={setSearch}
                isRerender={isRerender}
                setIsRerender={setIsRerender}
            />
        </CreateChannelProvider>
    );
};

export default CustomCreateChannelWrapper;