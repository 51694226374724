import React from 'react';
import classes from "./EditBusiness.module.scss";
import Dropzone from "../Components/Dropzone/Dropzone";
import Gallery from "../Components/Gallery/Gallery";

const CoverAndGallery = (
    {
        cover, coverName, business, businessId,galleryIsLoading, errors,
        handleCoverOnDrop, handleRemoveCover, handleGalleryChanges, deleteGalleryItem,
        handleGalleryDropzoneClick, handleGalleryOnDrop
    }) => {
    return (
        <div className={classes.Section}>
            <span className={classes.SubTitle}>Profile cover & Gallery</span>
            <label className={classes.SubTitle}>Cover</label>
            <div className={classes.DropzoneContainer}>
                <Dropzone
                    media={cover}
                    onChange={handleCoverOnDrop}
                    className={classes.CoverDropZone}
                />
                {
                    cover &&
                    <div className={classes.PendingMediaInfo}>
                        <span className={classes.LogoName}>{coverName}</span>
                        <button onClick={handleRemoveCover}> Remove image</button>
                    </div>
                }
            </div>
            <label className={classes.SubTitle}>Gallery</label>
            <Gallery
                className={classes.Gallery}
                items={business?.profileMedia}
                onAdd={handleGalleryChanges}
                onDelete={deleteGalleryItem}
                handleDropzoneClick={handleGalleryDropzoneClick}
                handleOnDrop={handleGalleryOnDrop}
                isLoading={galleryIsLoading}
            />
        </div>
    );
};

export default CoverAndGallery;