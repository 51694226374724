import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {useDispatch} from "react-redux";
import ApiClient from "../Api/client";
import {ROUTES} from "../Shared/constants";
import {executeIfExist} from "../Shared/utility/utils";

const Logout = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect( () => {
        const api = new ApiClient()
        if (props.updatedCurrentBusinessId) {
            dispatch(props.updatedCurrentBusinessId({
                currentBusinessId: null,
            }))
        }

        executeIfExist(props.resetCredentials)
        api.signOut()
        navigate(ROUTES.MAIN, {replace: true}) // redirect
    },[navigate, dispatch, props])

    return (
        <div>
            <span>{'Logging out...'}</span>
        </div>
    )
}

export default Logout
