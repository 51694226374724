import React from 'react';
import classes from './PostInfo.module.scss'
import {ReactComponent as Like} from "../../../Shared/UI/assets/like.svg";
import {ReactComponent as CommentIcon} from "../../../Shared/UI/assets/comment_Icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {creteLikeThunk, deleteLikeThunk, selectIsAdmin} from "../../../../../store/reducers/postsReducer";
import BusinessInfo from "../../BusinessInfo/BusinessInfo";
import UserInfo from "../../UserInfo/UserInfo";
import {useMentionData} from "../../../Shared/hooks/useMentionData";

const PostInfo = ({post}) => {

    const dispatch = useDispatch()

    const isAdminDashboard = useSelector(selectIsAdmin)

    const {getTextWithMentions} = useMentionData(post.text, post.mentions)

    const handleLikeIconClick = () => {
        if (post?.userLiked){
            dispatch(deleteLikeThunk(post.id))
        }
        else {
            dispatch(creteLikeThunk(post.id))
        }
    }

    return (
        <div className={classes.Container}>
            <div className={classes.Header}>
                {
                    post?.business
                        ? <BusinessInfo business={post?.business} createdAt={post.createdAt}/>
                        : <UserInfo user={post?.user} createdAt={post.createdAt}/>
                }

            </div>
            <img className={classes.Img}
                 src={post?.media?.[0]?.url}
                 alt=""/>
            <div className={classes.Buttons}>
                <div className={classes.SocialButtons}>
                    <Like
                        className={
                            post.userLiked
                                ? classes.LikeActive
                                : !isAdminDashboard
                                    ? classes.Like
                                    : '' }
                        width={20}
                        height={20}
                        onClick={
                            !isAdminDashboard
                                ? handleLikeIconClick
                                : ()=>{}
                        }
                    /> <span>{post?.likesCount}</span>
                    <CommentIcon width={20} height={20}/> <span>{post?.commentsCount}</span>
                </div>
            </div>
            <div className={classes.PostText}>
                <span>{getTextWithMentions()}</span>
            </div>

        </div>

    );
};

export default PostInfo;