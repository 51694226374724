import React, {useState} from 'react';
import {format as dateFormat} from "date-fns";
import {usePopper} from "react-popper";
import {usePopperClickOutside} from "../../../Shared/hooks/usePopperClickOutside";
import classes from './DateTimePicker.module.scss'
import TimePickerNew from "../TimePicker/TimePickerNew";
import DatePicker from "../DatePicker/DatePicker";

const DateTimePicker = (
    {
        value, placeholder, className, onChange,
        minDate, maxDate, format,
        ...rest
    }) => {

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const {onInputClick} = rest

    const {styles, attributes} = usePopper(referenceElement, popperElement, {});

    const {visible, toggleVisible} = usePopperClickOutside({referenceElement, popperElement})

    const handleInputClick = () => {
        toggleVisible()
        if (onInputClick) onInputClick()
    }

    const handleDateChange = (date) => {
        toggleVisible()
        onChange(date)
    }

    const handleTimeChange = (date) => {
        onChange(date, 'TIME')
    }


    return (
        <>
            <input
                className={`${classes.DateTimePickerInput} ${className ?? ''}`}
                ref={setReferenceElement}
                type="text"
                readOnly
                placeholder={placeholder}
                onClick={handleInputClick}
                value={value ? dateFormat(value, format) : ''}
            />
            {
                visible &&
                <div className={classes.DateTimePickerContainer} ref={setPopperElement}
                     style={styles.popper}  {...attributes.popper}>
                    <DatePicker
                        value={value}
                        isClearable={true}
                        placeholder={placeholder}
                        format={format}
                        onChange={handleDateChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        inline
                        {...rest}
                    />
                    <div className={classes.TimePickerContainer}>
                        <div className={classes.Label}>
                            <span>Time</span>
                        </div>
                        <TimePickerNew
                            className={classes.TimePicker}
                            value={value}
                            onChange={handleTimeChange}
                        />
                    </div>
                </div>
            }

        </>
    );
};

export default DateTimePicker;