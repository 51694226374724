import React, {useEffect, useState} from 'react';
import Geocode from "react-geocode";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import MarkerIcon from '../../Shared/UI/assets/marker.svg'
import {GOOGLE_LIBRARIES, GOOGLE_MAP_OPTIONS, MAIN_MARKER_ANCHOR, MAP_CONTAINER_STYLE} from "./constants";
import Polygons from "./Components/Polygons/Polygons";

const DEFAULT_API_KEY = 'AIzaSyCWK7AD2Ikh-a27xMaVNMOzOKZwRAm1LIc'

const MapComponent = ({
                          onClick,
                          defaultZoom = 15,
                          lat = 47.3666700,
                          lng = 8.55,
                          areaDrawable = false
                      }) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        : DEFAULT_API_KEY

    useEffect(() => {
        Geocode.setApiKey(apiKey)
    }, [apiKey])

    const [polygons, setPolygons] = useState([])

    const mapCenter = {
        lat,
        lng
    }

    const googleMapClick = (event) => {
        const lat = event.latLng.lat()
        const lng = event.latLng.lng()
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const address = response.results[0];
                onClick(address, lat, lng)
            },
            (error) => {
                console.error(error);
            })
    }

    return (
        <LoadScript
            googleMapsApiKey={apiKey}
            libraries={GOOGLE_LIBRARIES}
        >
            <GoogleMap
                options={GOOGLE_MAP_OPTIONS}
                mapContainerStyle={MAP_CONTAINER_STYLE}
                center={mapCenter}
                zoom={defaultZoom}
                onClick={googleMapClick}
            >
                {
                    areaDrawable &&
                    <Polygons polygons={polygons} setPolygons={setPolygons}/>
                }
                <Marker
                    position={mapCenter}
                    icon={{
                        url: MarkerIcon,
                        anchor: MAIN_MARKER_ANCHOR,
                    }}
                />
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;