import React from 'react'
import classes from './OfferScheduleCustomDayTimeInterval.module.scss'
import AddRangeIcon from '../Shared/UI/assets/add-range-icon.svg'
import RemoveRangeIcon from '../Shared/UI/assets/remove-range-icon.svg'
import TimePicker from "../Components/Date/TimePicker/TimePickerNew";
import {add} from "date-fns";

const getMinutesFromStartDay = (date) => date.getHours() * 60 + date.getMinutes()

const OfferScheduleCustomDayTimeInterval = (props) => {
  // start time
  const parseStartTime = (startTimeRaw) => add(new Date().setHours(0, 0, 0), { minutes: startTimeRaw})
  const startTimePicker = (
      <div className={classes.TimePickerContainer}>
          <TimePicker
              value={parseStartTime(props.startTime)}
              onChange={(newVal) => console.log(getMinutesFromStartDay(newVal)) || props.onStartTimeChange(getMinutesFromStartDay(newVal))}
              onClick={props.onStartTimeClick}
          />
      </div>
  )
  // end time
  const parseEndTime = (startTimeRaw, durationRaw) => add(new Date().setHours(0,0,0), {minutes: startTimeRaw + durationRaw})

  const endTimeLabel = (
      <div className={classes.TimePickerContainer}>
          <TimePicker
              value={parseEndTime(props.startTime, props.duration)}
              onChange={(newVal) => props.onEndTimeChange(getMinutesFromStartDay(newVal))}
              onClick={props.onEndTimeClick}
          />
      </div>
  )
  // add / remove range button
  const addRange = props.showAddRangeButton ? (
    <div
      onClick={props.onAddRangeClick}
      className={classes.AddRemoveRangeContainer}
    >
      <img alt="" src={AddRangeIcon} className={classes.AddRemoveRangeIcon} />
      <span className={classes.AddRemoveRangeText} style={{ color: '#3c49c6' }}>
        {'add range'}
      </span>
    </div>
  ) : null
  const removeRange = props.showRemoveRangeButton ? (
    <div
      className={classes.AddRemoveRangeContainer}
      onClick={() =>
        props.onRemoveRangeClick({
            startTimeMinutesSinceMidnight: props.startTimeMinutesSinceMidnight,
            durationMinutes: props.durationMinutes,
        })
      }
    >
      <img
        alt=""
        src={RemoveRangeIcon}
        className={classes.AddRemoveRangeIcon}
      />
      <span className={classes.AddRemoveRangeText} style={{ color: '#ff5722' }}>
        {'remove range'}
      </span>
    </div>
  ) : null
  return (
    <li className={classes.CreateOfferScheduleCustomDayTimeInterval}>
      <span className={classes.FromLabel}>{'From'}</span>
      {startTimePicker}
      <span className={classes.ToLabel}>{'to'}</span>
      {endTimeLabel}
      {addRange}
      {removeRange}
    </li>
  )
}

export default OfferScheduleCustomDayTimeInterval
