import React, {useEffect, useState} from 'react';
import '@sendbird/uikit-react/dist/index.css';
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import ChannelList from "@sendbird/uikit-react/ChannelList";
import Channel from "@sendbird/uikit-react/Channel";
import MessageSearch from "@sendbird/uikit-react/MessageSearch";
import classes from './Chat.module.scss'
import ChannelsListHeader from "./ChannelsListHeader/ChannelsListHeader";
import ChannelSettingsWrapper from "./ChannelSettingsWrapper/ChannelSettingsWrapper";
import {getSendbirdSessionToken} from "../../../api/chatApi";

const initialIsShowState = {
    settings: false,
    search: false,
}

const DEFAULT_SENDBIRD_APP_ID = 'EC97F7A5-9CF5-478B-984C-7FD328CD1C6E'

const Chat = ({userId}) => {
    const [tkn, setTkn] = useState(false)
    const [channel, setChannel] = useState(null)
    const [isShow, setIsShow] = useState(initialIsShowState)

    useEffect(() => {
        getSendbirdSessionToken()
            .then((res) => {
                setTkn(res.data.sendbirdSessionToken)
            })
    }, [])

    const handleSelectChannel = (channel) => {
        setChannel(channel)
    }

    const handleSearchIconClick = () => {
        setIsShow({
            search: !isShow.search
        })
    }

    const handleSettingsClick = () => {
        setIsShow({
            settings: !isShow.settings
        })
    }

    const handleCloseIconClick = () => {
        setIsShow(initialIsShowState)
    }

    return (
        <div className={classes.Container}>
            {
                tkn &&
                <SendbirdProvider
                    appId={process.env.REACT_APP_SENDBIRD_APP_ID ?? DEFAULT_SENDBIRD_APP_ID}
                    userId={userId}
                    accessToken={tkn}
                    isMentionEnabled
                >
                    <div className={classes.ChannelListWrap}>
                        <ChannelList
                            onChannelSelect={handleSelectChannel}
                            renderHeader={ChannelsListHeader}
                        />
                    </div>
                    <div className={classes.ConversationWrap}>
                        <Channel
                            channelUrl={channel?.url ?? null}
                            showSearchIcon
                            onSearchClick={handleSearchIconClick}
                            onChatHeaderActionClick={handleSettingsClick}
                            replyType={'QUOTE_REPLY'}
                        />
                    </div>
                    <div className={classes.SettingsWrap}>
                        {
                            isShow.search &&
                            <MessageSearch
                                channelUrl={channel?.url ?? null}
                                onCloseClick={handleCloseIconClick}
                                onResultClick={()=>{}}
                            />
                        }
                        <ChannelSettingsWrapper
                            channel={channel}
                            isShow={isShow.settings}
                            onClose={handleCloseIconClick}
                        />
                    </div>
                </SendbirdProvider>
            }
        </div>
    );
};

export default Chat;