import React from 'react'
import classes from './SubmitButton.module.scss'
import {Preloader} from "../../Components/Preloader/Preloader";

const submitButton = ({disabled, onClick, isLoading}) => {
  return (
    <button
      disabled={disabled}
      className={classes.SubmitButton}
      onClick={onClick}
    >
      {
        isLoading
            ? <Preloader className={classes.LoadingIndicator}/>
            : 'Log in'
      }
    </button>
  )
}

export default submitButton
