import React, {Fragment, useEffect, useMemo} from 'react';
import classes from "./TagOptions.module.scss";
import spinnerClasses from '../../../../../app/Spinner.module.scss'
import {useTagPagination} from "../useTagPagination";
import {unwrapResult} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {fetchTags, fetchTagsByNextToken} from "../../../../../store/reducers/tagsReducer";
import {returnObservableItem} from "../../../Shared/utility/utils";
import {Preloader} from "../../Preloader/Preloader";
import {ReactComponent as PlusIcon} from "../../../Shared/UI/assets/add-range-icon.svg";

const TagOptions = ({
                        options, onItemClick, values=[], searchString='',
}) => {
    const dispatch = useDispatch()
    const [setElement, setRootElement, limit, fetching ] = useTagPagination()

    useEffect(() => {
        dispatch(fetchTags({
            q:searchString
        }))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                if (originalPromiseResult.nextToken) {
                    dispatch(fetchTagsByNextToken({
                        nextToken: originalPromiseResult.nextToken
                    }))
                }
            })
    }, [searchString, dispatch])

    const handleCreateTag = () => {
       onItemClick({
           id:searchString,
           title: searchString
       })
    }
    const isNotExist = useMemo(() => {
        return options?.every(option => option.id !== searchString) && options.length !== 0 && searchString !== ''
    }, [options, searchString])


    return (
        <>
            <div className={classes.OptionsContainer} ref={setRootElement}>
                {
                    ((searchString!=='' && options.length === 0) || isNotExist) &&
                    <div
                        className={`${classes.CreateTagContainer} ${!options.length ? classes.IsAlone : ''} `}
                        onClick={handleCreateTag}
                    >
                        <PlusIcon width={10} height={10} className={classes.Icon} />
                        <span>Create tag ‘{searchString}’</span>
                    </div>
                }
                {options
                    ?.filter((item) => {
                        const isSelected = values.find((value) => {
                            return item?.id === value?.id
                        })
                        return ((item.id?.toLowerCase().includes(searchString)) && !isSelected)
                    })
                    ?.map((item, index, array) => {
                        return (
                            <Fragment key={index}>
                                <div
                                    key={index}
                                    className={classes.Option}
                                    onClick={()=>onItemClick(item)}
                                >
                                    <span className={classes.OptionTitle}>{item.id}</span>
                                </div>
                                {
                                    returnObservableItem(array.length, limit, index, setElement )
                                }
                            </Fragment>
                        )
                    })}
                {
                    fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                }
            </div>

        </>

    );
};

export default TagOptions;