import React from 'react';
import classes from './Gallery.module.scss'
import {Preloader} from "../Preloader/Preloader";
import GalleryItem from "./GalleryItem/GalleryItem";
import Dropzone from "../Dropzone/Dropzone";

const Gallery = (
    {
        items, onAdd, onDelete, isLoading,
        className, itemClassName, dropzoneClassName,
        handleOnDrop, handleDropzoneClick
    }) => {

    const handleDropZoneChange = (files) => {
        onAdd(files.map((file) => URL.createObjectURL(file)))
    }

    const handleDeleteClick = (deleteKey) => () => {
        onDelete(deleteKey)
    }

    return (
        <div className={`${classes.Container} ${className ?? ''}`}>
            <Dropzone
                className={`${classes.Dropzone} ${dropzoneClassName ?? ''}`}
                onChange={handleDropZoneChange}
                onDropAnalytics={handleOnDrop}
                onClickAnalytics={handleDropzoneClick}
            />
            {isLoading && <Preloader/>}
            {
                items?.map((item) =>
                    <GalleryItem
                        key={item?.id ?? item}
                        className={itemClassName}
                        media={item?.url ?? item}
                        onDelete={handleDeleteClick(item?.id ?? item)}
                    />
                )}
        </div>
    );
};

export default Gallery;