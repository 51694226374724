import React from 'react';
import classes from './ModalWindow.module.scss'
import {ReactComponent as CloseIcon} from "../../Shared/UI/assets/closeIcon.svg";
import {BUTTON_TYPES, Button} from "../Button/Buttons";
import PropTypes from "prop-types";
import {useOnClickOutside} from "../../Shared/hooks/useclickOutside";
import Portal from "../Portal/Portal";

const ModalWindow = (
    {
        className,
        title, subtitle,
        secondaryButton, handleSecondaryClick,
        primaryButton, handlePrimaryClick,
        isOpen, onClose, isOutsideClosed,
        children, disabledPrimary, disabledSecondary
    }) => {
    const ref = useOnClickOutside(onClose)
    return (
        <>
            {
                isOpen &&
                <Portal>
                    <div className={classes.ModalOverlay}>
                        <div ref={isOutsideClosed ? ref : null} className={`${classes.Container} ${className}`}>
                            <CloseIcon
                                className={classes.CloseIcon}
                                width={17} height={17}
                                onClick={onClose}
                            />
                            {
                                title &&
                                <div className={classes.Title}>
                                    <span>{title}</span>
                                </div>
                            }
                            {
                                subtitle &&
                                <div className={classes.Subtitle}>
                                    <span>{subtitle}</span>
                                </div>
                            }
                            {children}

                            {
                                (secondaryButton || primaryButton) &&
                                <div className={classes.ButtonsContainer}>
                                    {
                                        secondaryButton &&
                                        <Button
                                            onClick={handleSecondaryClick}
                                            variant={BUTTON_TYPES.SECONDARY}
                                            disabled={disabledSecondary}
                                        >{secondaryButton}</Button>
                                    }
                                    {
                                        primaryButton &&
                                        <Button
                                            onClick={handlePrimaryClick}
                                            disabled={disabledPrimary}
                                        >{primaryButton}</Button>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </Portal>
            }
        </>
    );
};

ModalWindow.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    secondaryButton: PropTypes.string,
    handleSecondaryClick: PropTypes.func,
    primaryButton: PropTypes.string,
    handlePrimaryClick: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    isOutsideClosed: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default ModalWindow;