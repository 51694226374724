export const generateUrlWithQueryParams = (baseUrl, parameters) => {
    if (typeof baseUrl !== 'string') {
        throw new TypeError('baseUrl should be a string')
    }

    if (typeof parameters !== 'object') {
        throw new TypeError('baseUrl should be an object')
    }

    if (!!parameters.nextToken){
        return baseUrl + '?nextToken=' + parameters.nextToken
    }

    return baseUrl + '?' + Object.entries(parameters)
        .filter(entry => !!entry[1])
        .map((entry) => {
            return `${entry[0]}=${entry[1]}`
        })
        .join('&')
}

