import React from 'react';
import classes from './Radio.module.scss'
import {ReactComponent as CheckIcon} from "../../Shared/UI/assets/check.svg";

const Radio = ({ className, name, label, value, checked, onChange, disabled, type }) => {
    const cls = []
    if (disabled) cls.push(classes.Disabled)

    if (type === 'CIRCLE'){
        cls.push(classes.CircleContainer)
        return (
            <div className={cls.join(' ')}>
                <label>
                    <input name={name} disabled={disabled} type="radio" value={value} checked={checked} onChange={onChange}/>
                    <span>
                        <CheckIcon width={16} height={16}/>
                    </span>
                    {label}
                </label>
            </div>
        )
    }

    cls.push(classes.Container)

    return (
        <div className={cls.join(' ')}>
            <label>
                <input name={name} disabled={disabled} type="radio" value={value} checked={checked} onChange={onChange}/>
                <span className={`${className}`}>{label}</span>
            </label>
        </div>
    );
};

export default Radio;
