import React from 'react';
import classes from './Burger.module.scss'

const Burger = ({isActive, className, onClick}) => {
    return (
        <div className={`${classes.Container} ${className ?? ''}`} onClick={onClick}>
            <span className={`${isActive ? classes.active : ''}`}/>
        </div>
    );
};

export default Burger;