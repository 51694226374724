import React, {useContext} from 'react'
import {useDispatch} from 'react-redux'
import classes from './OfferScheduleCustomDay.module.css'
import CheckboxSelectedIcon from '../Shared/UI/assets/CheckBoxSelected.svg'
import CheckboxNotSelectedIcon from '../Shared/UI/assets/CheckBoxNotSelected.svg'
import OfferScheduleCustomDayTimeInterval from './OfferScheduleCustomDayTimeInterval'
import {AnalyticsContext} from "../Shared/context/analyticsContext";
import {executeIfExist} from "../Shared/utility/utils";


const WEEKDAYS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
]

const NINE_HOUR_MINUTES = 540



const OfferScheduleCustomDay = (props) => {
    const dispatch = useDispatch()
    const analyticsEvents = useContext(AnalyticsContext)

    const newOfferData = props.offer

    const addDefaultIntervalForCurrentWeekday = () => {
        let hs = newOfferData.customHours ? [...newOfferData.customHours] : []
        hs.push({
            weekday: props.weekday,
            startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
            durationMinutes: NINE_HOUR_MINUTES,
        })
        dispatch(
            props.updateOffer({
                customHours: hs
            })
        )
    }

    const intervalsForThisDay = newOfferData.customHours
        ? newOfferData.customHours.filter((h) =>
            h.weekday !== undefined ? h.weekday === props.weekday : false
        )
        : []

    const handleIsEnabledCheckboxClick = () => {
        executeIfExist(analyticsEvents?.custom_schedule_hours_day_btn_clicked)
        // remove old hours for this day anyway
        if (newOfferData.customHours) {
            const newHours = [
                ...newOfferData.customHours.filter((h) => h.weekday !== props.weekday),
            ]
            dispatch(
                props.updateOffer({
                    customHours: newHours
                })
            )
        }

        // if day was disabled, then new isEnabled is true, add the default hours (09:00 - 18:00)
        if (intervalsForThisDay?.length === 0) {
            addDefaultIntervalForCurrentWeekday()
        }
    }

    const onAddIntervalClick = () => {
        executeIfExist(analyticsEvents?.hours_add_range_btn_clicked)
        addDefaultIntervalForCurrentWeekday()
    }

    const onRemoveIntervalClick = (interval) => () => {
        executeIfExist(analyticsEvents?.hours_remove_range_btn_clicked)

        if (newOfferData.customHours) {
            const newHours = [
                ...newOfferData.customHours.filter(
                    (h) =>
                        h.weekday !== props.weekday ||
                        h.startTimeMinutesSinceMidnight !== interval.startTimeMinutesSinceMidnight ||
                        h.durationMinutes !== interval.durationMinutes
                ),
            ]
            dispatch(props.updateOffer({customHours: newHours}))
        }
    }

    const onIntervalStartTimeChange = (interval, index, array, newVal) => {
        executeIfExist(analyticsEvents?.hours_from_dropdown_item_clicked)

        let newHours = [...newOfferData.customHours]

        const curr = newHours.find(
            (h) =>
                h.weekday === interval.weekday &&
                h.startTimeMinutesSinceMidnight === interval.startTimeMinutesSinceMidnight &&
                h.durationMinutes === interval.durationMinutes
        )

        const updated = {...curr, startTimeMinutesSinceMidnight: newVal}
        const i = newHours.indexOf(curr)
        newHours[i] = updated
        dispatch(props.updateOffer({
            customHours: newHours
        }))
    }

    const onIntervalEndTimeChange = (interval, index, array, newVal) => {
        executeIfExist(analyticsEvents?.hours_to_dropdown_item_clicked)
        const newDurationFormatted = newVal - interval.startTimeMinutesSinceMidnight

        let newHours = [...newOfferData.customHours]

        const curr = newHours.find(
            (h) =>
                h.weekday === interval.weekday &&
                h.startTimeMinutesSinceMidnight === interval.startTimeMinutesSinceMidnight &&
                h.durationMinutes === interval.durationMinutes
        )
        const updated = {...curr, durationMinutes: newDurationFormatted}
        const i = newHours.indexOf(curr)
        newHours[i] = updated
        dispatch(props.updateOffer({
            customHours: newHours
        }))
    }

    const handleOnStartTimeClick = () => {
        executeIfExist(analyticsEvents?.hours_from_field_clicked)
    }

    const handleOnEndTimeClick = () => {
        executeIfExist(analyticsEvents?.hours_to_field_clicked)
    }

    return (
        <li className={classes.Container}>
            <div className={classes.TitleContainer}>
                <img
                    className={classes.IsEnabledCheckbox}
                    src={intervalsForThisDay?.length !== 0 ? CheckboxSelectedIcon : CheckboxNotSelectedIcon}
                    alt=""
                    onClick={handleIsEnabledCheckboxClick}
                />
                <span
                    className={classes.TitleLabel}
                    onClick={handleIsEnabledCheckboxClick}
                >
          {props.weekday >= 0 && props.weekday < 7
              ? WEEKDAYS[props.weekday]
              : null}
        </span>
            </div>
            <ul className={classes.TimeIntervalsList}>
                {intervalsForThisDay.map((interval, index, array) => (
                    <OfferScheduleCustomDayTimeInterval
                        key={index}
                        startTime={interval.startTimeMinutesSinceMidnight}
                        duration={interval.durationMinutes}
                        showAddRangeButton={index === array.length - 1} // show add range button if this is the last element
                        showRemoveRangeButton={index < array.length - 1} // show remove range button if this is no the last element
                        onAddRangeClick={onAddIntervalClick}
                        onRemoveRangeClick={onRemoveIntervalClick(interval)}
                        onStartTimeChange={(newVal) =>
                            onIntervalStartTimeChange(interval, index, array, newVal)
                        }
                        onEndTimeChange={(newVal) =>
                            onIntervalEndTimeChange(interval, index, array, newVal)
                        }
                        onStartTimeClick={handleOnStartTimeClick}
                        onEndTimeClick={handleOnEndTimeClick}
                    />
                ))}
            </ul>
        </li>
    )
}

export default OfferScheduleCustomDay
