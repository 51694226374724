import React, {useState} from 'react';
import classes from "./MoreDropdown.module.scss";
import {ReactComponent as MoreIcon} from "../../Shared/UI/assets/more.svg";
import {ReactComponent as KebabIcon} from "../../Shared/UI/assets/kebab.svg";
import {useOnClickOutside} from "../../Shared/hooks/useclickOutside";
import {executeIfExist} from "../../Shared/utility/utils";

const MoreDropdown = ({vertical, width, bottomPosition, onClick, children}) => {
    const [isShow, setIsShow] = useState(false)

    const ref = useOnClickOutside(() => {
        setIsShow(false)
    })

    const handleMoreIconClick = (event) => {
        event.stopPropagation()
        executeIfExist(onClick)
        setIsShow(!isShow)
    }
    return (
        <div className={classes.MoreIconContainer} ref={ref}>
            {
                vertical
                    ? <KebabIcon onClick={handleMoreIconClick}/>
                    : <MoreIcon onClick={handleMoreIconClick}/>
            }

            {
                isShow &&
                <div className={`${classes.MoreContainer} ${bottomPosition ? classes.Vertical : ''}`} style={{width}}>
                    {
                        React.Children.map(children, child => {
                            if (child) {
                                return React.cloneElement(child, {
                                    onClick: (event) => {
                                        event.stopPropagation()
                                        child.props.onClick(event)
                                        setIsShow(false)
                                    },
                                })
                            }
                        })
                    }
                </div>
            }
        </div>
    );
};

export default MoreDropdown;