import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    messages: []
}

export const addMessageThunk = createAsyncThunk(
    'messages/addMessageThunk',
    async ({message, timeout = 5000}, {dispatch}) => {
        dispatch(addMessage(message))
        setTimeout(() => {
            dispatch(removeMessage())
        }, timeout)
    }
)


const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessage: (state, action) => {
            state.messages.unshift(action.payload)
        },
        removeMessage: (state, action) => {
           state.messages.pop()
        }
    }
})

export default messagesSlice.reducer

export const {addMessage, removeMessage} = messagesSlice.actions

export const selectMessages = state => state.messages.messages


