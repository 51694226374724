import React from 'react';
import classes from "../Input/Input.module.scss";
import {Mention, MentionsInput} from "react-mentions";
import mentionClasses from "./mentions.module.scss";
import mentionsCommentsClasses from './mentionsComments.module.scss'
import Avatar from "../Avatar/Avatar";
import {addHighlights} from "../../Shared/utility/utils";
import {getBusinesses} from "../../../../api/businessApi";
import {getUsers} from "../../../../api/userApi";



const TextArea = React.forwardRef(
    ({
            label, name, type, onChange, placeholder,
         value, error, maxLength, styles, className,
         Icon, copy, readOnly, mentions
     }, ref) => {

        const mentionsClassNames = type === "COMMENT" ? mentionsCommentsClasses : mentionClasses

        const  fetchUsers = (query, callback) => {
            if (!query) return
                getUsers({q:query})
                .then(response=>(
                    response.data?.users?.map(user => (
                        {
                            display: `${user.firstname} ${user.lastname}`,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            id: user.id,
                            logo: user?.avatar?.url,
                            userId: user.id
                        }))
                ))
                .catch((error=>{
                    console.log(error)}))
                .then(callback)
        }

        const  fetchBusiness = (query, callback) => {
            getBusinesses({q:query})
                .then((response)=>(
                    response.data?.businesses?.map(business => (
                        {
                            display: business.name,
                            id: business.id,
                            logo: business?.logo?.url,
                            businessId: business.id
                        }
                    ))
                ))
                .catch((error=>{
                    console.log(error)}))
                .then(callback)
        }

        const renderSuggestions = (entry, search) => {
            if (entry.userId){
                return  <div
                    className={mentionClasses.mentions__suggestions__customItem}
                >
                    <Avatar
                        img={entry.logo}
                        name={entry.firstname}
                        lastname={entry.lastname}
                    />
                    <span
                        dangerouslySetInnerHTML={{__html: addHighlights(entry.display, search, mentionClasses.Highlights )}}
                    />
                </div>
            }
            return <div
                className={mentionClasses.mentions__suggestions__customItem}
            >
                <Avatar
                    img={entry.logo}
                    name={entry.display}
                />
                <span
                    dangerouslySetInnerHTML={{__html: addHighlights(entry.display, search, mentionClasses.Highlights )}}
                />
            </div>
        }
    return (
        <>
            {label && <label className={classes.Label}>{label}</label>}
                <MentionsInput
                    value={value}
                    onChange={onChange}
                    classNames={mentionsClassNames}
                    allowSpaceInQuery={true}
                    allowSuggestionsAboveCursor={true}
                    placeholder={placeholder}
                >
                    <Mention
                        className={mentionClasses.mentions__mention}
                        trigger={'@'}
                        markup={"[<<__display__>>]{<<__id__>>}"}
                        data={fetchBusiness}
                        displayTransform={(id, display)=>'@'+display}
                        renderSuggestion={renderSuggestions}
                    />
                    <Mention
                        className={mentionClasses.mentions__mention}
                        trigger={'@'}
                        data={fetchUsers}
                        markup={"[**__display__**]{**__id__**}"}
                        displayTransform={(id, display)=>{
                           return  '@'+display
                        }}
                        renderSuggestion={renderSuggestions}
                    />
                    <Mention
                        className={mentionClasses.mentions__mention}
                        markup={"<@__id__>"}
                        data={()=>{}}
                        displayTransform={(id)=>{
                            const userMention = mentions?.find((item)=>item?.user?.id === id)
                            if (userMention){
                                return  `@${userMention?.user?.firstname} ${userMention?.user?.lastname}`
                            }
                            const businessMention = mentions?.find((item)=>item?.business?.id === id)
                            if (businessMention){
                                return  `@${businessMention?.business?.name}`
                            }
                        }}
                    />

                </MentionsInput>
        </>
    );
});

export default TextArea;