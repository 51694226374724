import React from 'react';
import CategoriesDropdown from "./CategoriesDropdown/CategoriesDropdown";
import classes from "./EditBusiness.module.scss";
import Dropzone from "../Components/Dropzone/Dropzone";

const NameCategoryAboutAndLogo = (
    {
        business, errors, categories, logo, logoName,
        handleNameChange, handleCategoryChange, handleAboutChange,
        handleLogoOnDrop, handleRemoveLogo, handleCategoryFieldClick
    }) => {
    return (
        <div className={classes.Section}>
            <span className={classes.SubTitle}>Business name, Category, About & Logo</span>
            <label className={classes.InputTitle}>Name</label>
            <div className={classes.NameContainer}>
                <input
                    className={`${classes.Input} ${errors.name && classes.InputError}`}
                    name={'name'}
                    type="text"
                    placeholder={'Your public business name'}
                    value={business.name}
                    onChange={handleNameChange}
                    maxLength={48}
                />
                <span className={classes.NameLength}>{business?.name?.length}/48</span>
            </div>
            <label className={classes.InputTitle}>Category</label>
            <CategoriesDropdown
                list={categories}
                selectedList={business.businessCategories}
                onChange={handleCategoryChange}
                onClick={handleCategoryFieldClick}
            />
            <div className={classes.DescriptionContainer}>
                <span className={classes.BusinessDescriptionLabel}>About</span>
                <textarea
                    name={'description'}
                    className={classes.BusinessDescriptionInput}
                    placeholder="Describe your business"
                    value={business.description}
                    maxLength={512}
                    onChange={handleAboutChange}
                />
                <span className={classes.DescriptionLength}>{business?.description?.length || 0}/512</span>
            </div>
            <span className={classes.SubTitle}>Logo</span>
            <div className={classes.DropzoneContainer}>
                <Dropzone
                    media={logo}
                    onChange={handleLogoOnDrop}
                    className={classes.LogoDropZone}
                />
                {
                    logo &&
                    <div className={classes.PendingMediaInfo}>
                        <span className={classes.LogoName}>{logoName}</span>
                        <button onClick={handleRemoveLogo}>
                            Remove image
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default NameCategoryAboutAndLogo;