import axios from 'axios'

import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js'

const ADMIN_API_URL_PATH = '/admin-v1'
const MAIN_API_URL_PATH = '/v1'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'https://api.dev.naoo.io'

export const COGNITO_VARIABLES = {
    COGNITO_USERPOOL_ID: process.env.REACT_APP_COGNITO_USERPOOL_ID ?? 'eu-west-1_37ox0YB6L',
    COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID ?? '70hsn3kp8fdf08otdejsft2t8s',
    COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID ?? 'eu-west-1:e903c33a-33db-4bfc-a5cc-735af99b0be5'
}

class ApiClient {
    constructor() {
        // create cognito user pool - used to sign in and get jwt
        this.userPool = new CognitoUserPool({
            UserPoolId: COGNITO_VARIABLES.COGNITO_USERPOOL_ID,
            ClientId: COGNITO_VARIABLES.COGNITO_CLIENT_ID,
        })
        // create admin api axios instance
        this.admin = axios.create({
            baseURL: API_BASE_URL + ADMIN_API_URL_PATH
        })
        // add jwt to admin api requests using axios interceptor
        this.admin.interceptors.request.use(
            this.setRequestHeaders.bind(this),
            this.logRequestError
        )
        // create main api axios instance
        this.main = axios.create({
            baseURL: API_BASE_URL + MAIN_API_URL_PATH
        })
        // add jwt to main api requests using axios interceptor
        this.main.interceptors.request.use(
            this.setRequestHeaders.bind(this),
            this.logRequestError
        )
    }

    signUP(login, password, attributes, onSuccess, onFailure) {
        console.log('signUp')
        this.userPool.signUp(login, password, attributes, null, function (err, res) {
            if (err) {
                console.error(err)
                onFailure(err)
                return
            }
            onSuccess()
            console.log(res)
            const cognitoUser = res.user;
            console.log('user name is ' + cognitoUser.getUsername());
        })
    }
    confirmRegistration(code, user, onSuccess, onFailure) {
        user.confirmRegistration(code, true, function (err, result) {
            if (err) {
                // alert(err.message || JSON.stringify(err))
                onFailure(err)
                return;
            }
            onSuccess(result)
        });
    }

    resendConfirmationCode(user, onSuccess, onFailure) {
        user.resendConfirmationCode(function (err, result) {
            if (err) {
                onFailure(err)
                // alert(err.message || JSON.stringify(err));
                return;
            }
            onSuccess(result)
            console.log('call result: ' + result);
        });
    }

    forgotPassword(login, onSuccess, onFailure) {
        const user = new CognitoUser({
            Username: login,
            Pool: this.userPool,
        })
        user.forgotPassword({
            onSuccess: (data) => {
                onSuccess(data)
            },
            onFailure: (err) => {
                onFailure(err)
            },
            inputVerificationCode: (data) => {
                onSuccess(data)
            }
        })
    }

    confirmPassword(code, password, login, onSuccess, onFailure) {
        const user = new CognitoUser({
            Username: login,
            Pool: this.userPool,
        })
        user.confirmPassword(code.toString(), password, {
            onSuccess: (data) => {
                onSuccess(data)
            },
            onFailure: (err) => {
                onFailure(err)
            }
        })
    }

    // User sign in with Cognito
    signIn(login, password, onStart, onSuccess, onFailure) {
        console.log('signing in...')
        const user = new CognitoUser({
            Username: login,
            Pool: this.userPool,
        })
        onStart()
        user.authenticateUser(
            new AuthenticationDetails({
                Username: login,
                Password: password,
            }),
            {
                onSuccess: (result) => {
                    console.debug('auth result: ', result)
                    onSuccess(result)
                },
                onFailure: (error) => {
                    console.debug('auth error: ', error)
                    onFailure(error)
                },
            }
        )
    }

    getIsSignedIn() {
        return this.userPool.getCurrentUser() !== null
    }

    // User sign out with Cognito
    signOut() {
        return new Promise((resolve) => {
            this.userPool.getCurrentUser().signOut()
            resolve()
        }).then(()=>{
            console.log('Logged out...')})
    }

    // helper - gets cognito jwt for signed in user
    getCognitoJwt(onSuccess, onFailure) {
        const currentUser = this.userPool.getCurrentUser()
        if (currentUser) {
            currentUser.getSession((err, session) => {
                if (err) {
                    console.log(err)
                    onFailure(err)
                } else if (!session.isValid()) {
                    console.log('Invalid session.')
                    onFailure(Error('invalid session'))
                } else {
                    const jwt = session.getIdToken().getJwtToken()
                    //console.log('JWT: ' + jwt)
                    onSuccess(jwt)
                }
            })
        } else {
            console.log('Current user not found.')
            onFailure(Error('current user not found'))
        }
    }

    // helper - adds cognito jwt and x-api-version to axios request (used by interceptors)
    setRequestHeaders(config) {
        config.headers['X-Web-Admin-App-Version'] =  '3.0.0'
        // create promise that calls aws sdk to get cognito jwt
        const getJwt = new Promise((resolve, reject) => {
            this.getCognitoJwt(
                (jwt) => {
                    resolve(jwt)
                },
                (err) => {
                    reject(err)
                }
            )
        })
        return getJwt
            .then((jwt) => {
                // console.log('adding jwt to header: \n' + jwt)
                config.headers.Authorization = jwt
                return config
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    // helper - logs error in axios http request (used by interceptors)
    logRequestError = (error) => {
        console.log(`http request failed with error ${error}`)
        return Promise.reject(error)
    }

    getCurrentUser = () => {
        return this.userPool.getCurrentUser()
    }
}

export default ApiClient
