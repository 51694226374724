import React, {useEffect, useState} from 'react';
import classes from './Chart.module.scss'
import {AreaChart, Area, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer} from "recharts";
import CustomTooltip from "./CustomTooltip";

const Chart = (
    {
        title='Impressions in Feed',
        data,
        XAxisDataKey,
        valueDataKey,
        width=324,
        height=232
    }

) => {

    const [widthYAxis, setWidthYAxis] = useState(60)

    useEffect(() => {
        const maxDataValue = Math.max(...data.map(item => item[valueDataKey]))
        setWidthYAxis(maxDataValue.toString().length * 7 + 10 + 16)
    }, [data, valueDataKey])

    return (
        <div className={classes.Container}>
            <div className={classes.Title}>
                <span>{title}</span>
            </div>
            <div className={classes.ChartContainer}>
                <ResponsiveContainer width={'99%'} height={height}>
                    <AreaChart
                        data={data}
                        margin={{top: 0, right: 7, left: 0, bottom: 12}}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid vertical={false} strokeDasharray="8 8" stroke={'#F0F1FB'} />
                        <XAxis
                            dataKey={XAxisDataKey}
                            axisLine={false}
                            minTickGap={33}
                            height={20}
                            tickLine={false}
                            allowDecimals={false}
                            style={{
                                fontFamily: "Roboto, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5E5F70",
                            }}

                        />
                        <YAxis
                            width={widthYAxis}
                            axisLine={false}
                            tickLine={false}
                            allowDecimals={false}
                            padding={{ top: 7, bottom: 0 }}
                            style={{
                                fontFamily: "Roboto, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5E5F70",
                            }}
                        />
                        <Tooltip content={<CustomTooltip/>}/>
                        <Area
                            type="monotone"
                            dataKey={valueDataKey}
                            stroke="#3C49C6"
                            strokeWidth={2}
                            activeDot={{
                                stroke: '#1F2A99',
                                fill:'#fff',
                                strokeWidth: 2,
                                r: 5,
                                cursor: 'pointer',
                            }}
                            fill="url(#colorUv)"
                            fillOpacity={0.5}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Chart;