import React, {useEffect} from 'react';
import classes from './QrCode.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import QRCode from "qrcode.react";
import {createDeepLink, selectDeepLink, selectStatus} from "../../../../store/reducers/postsReducer";
import {useDispatch, useSelector} from "react-redux";
import {Preloader} from "../../Components/Preloader/Preloader";
import ModalOverlay from "../../Components/ModalOverlay/ModalOverlay";
import SwipeWrapper from "../../Components/Draggable/SwipeWrapper";
import Portal from "../../Components/Portal/Portal";

const QrCodeComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const deepLink = useSelector(selectDeepLink)
    const {id} = useParams()
    const status = useSelector(selectStatus)

    const handleCloseClick = () => {
        navigate(-1)
    }

    useEffect(() => {
        dispatch(createDeepLink({
            postId: id
        }))
    }, [id, dispatch])

    return (
        <Portal>
            <ModalOverlay onClose={handleCloseClick}>
                <SwipeWrapper handler={handleCloseClick}>
                    <div className={classes.Qr}>
                        {
                            status === 'loading'
                                ? <Preloader/>
                                : <QRCode
                                    id={'qr-gen'}
                                    size={550}
                                    includeMargin={true}
                                    value={deepLink}
                                />
                        }
                    </div>
                </SwipeWrapper>
            </ModalOverlay>
        </Portal>
    );
};

export default QrCodeComponent;