import axios from "axios";
import {FIREBASE_VARIABLES} from "../envVariables";


export const createPostDeepLink = (link) => {
    return  axios.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_VARIABLES.API_KEY}`,
        {
            "dynamicLinkInfo": {
                "domainUriPrefix": FIREBASE_VARIABLES.DOMAIN_URI_PREFIX,
                "link": link,
                "androidInfo": {
                    "androidPackageName": FIREBASE_VARIABLES.ANDROID_BUNDLE_ID,
                    "androidFallbackLink": FIREBASE_VARIABLES.FALLBACK_URL,
                },
                "iosInfo": {
                    "iosBundleId": FIREBASE_VARIABLES.IOS_BUNDLE_ID,
                    "iosFallbackLink": FIREBASE_VARIABLES.FALLBACK_URL,
                },
            },
            "suffix": {
                "option": "SHORT"
            }
        }
    )
}


