import React from 'react'

import classes from './OfferPreviewPriceAndDiscount.module.css'

const OfferPreviewPriceAndDiscount = (props) => {
    const price = props.price ? `${props.price } CHF` : null
    const discount = props.discount ? `-${props.discount} CHF` : null
    const discountPercent = props.discountPercent >= 1 ? `${props.discountPercent } %` : null


    return (
        <>
            {
                price &&
                <div className={classes.Container}>
                    <span className={classes.Price}>{price}</span>
                    <div className={classes.DiskountAndPercentContainer}>
                        {
                            discount &&
                            <span className={classes.Discount}>{discount}</span>
                        }

                        {
                            discountPercent &&
                            <span className={classes.DiscountPercent}>{discountPercent}</span>
                        }

                    </div>
                </div>
            }
        </>

    )
}

export default OfferPreviewPriceAndDiscount
