import React from 'react';
import classes from './Chart.module.scss'

const CustomTooltip = ({active, payload, label}) => {
    if(active && payload?.length)
    return (
        <div className={classes.CustomTooltip}>
            <span className={classes.Date}>{label}</span>
            <span className={classes.Value}>{payload?.[0]?.value}</span>
        </div>
    );
    return null
};

export default CustomTooltip;