import { mainUploadMediaToS3 } from '../../Api/mediaS3Api'

const getDimensions = async (url) => {
	return new Promise((resolve) => {
		const img = new Image()
		const dimensions = {}
		img.onload = function () {
			dimensions.width = this.width
			dimensions.height = this.height
			resolve(dimensions)
		}
		img.src = url
	})
}

const getVideoDimensions = (url) => {
	return new Promise((resolve) => {
		const video = document.createElement('video')
		video.addEventListener(
			'loadedmetadata',
			function () {
				const height = this.videoHeight
				const width = this.videoWidth
				resolve({ height, width })
			},
			false
		)
		video.src = url
	})
}

export const sendImage = async (imgUrl, createMediaPresignedUrlRequest, getMediaRequest, userId = null) => {
	const mediaData = await fetch(imgUrl).then((res) => res.blob())
	let dimensions

	if (mediaData.type.includes('video')) {
		dimensions = await getVideoDimensions(imgUrl)
	} else if (mediaData.type.includes('image')) {
		dimensions = await getDimensions(imgUrl)
	}

	//Create a presigned url to upload media
	const mediaPresignedUrlRequestParameters = [mediaData.type, `${dimensions?.width},${dimensions?.height}`]
	if (userId) {
		mediaPresignedUrlRequestParameters.push(userId)
	}

	const createPresignedUrlResp = await createMediaPresignedUrlRequest(...mediaPresignedUrlRequestParameters)

	// check http code
	if (createPresignedUrlResp.status < 200 || createPresignedUrlResp.status >= 300) {
		console.debug('could not create presigned url, http status: ', createPresignedUrlResp.status)
	}
	console.debug(`[createOfferSlice.js] create presigned url response: `, createPresignedUrlResp)
	// Upload media to S3 using the presigned url and headers
	const mediaId = createPresignedUrlResp.data['mediaId']
	const uploadToS3Resp = await mainUploadMediaToS3(
		createPresignedUrlResp.data['presignedUploadUrl'],
		createPresignedUrlResp.data['headers'],
		mediaData
	)
	console.debug(`[createOfferSlice.js] uploaded media to s3, response: `, uploadToS3Resp)
	// check http code
	if (uploadToS3Resp.status < 200 || uploadToS3Resp.status >= 300) {
		console.debug('could not upload media to s3, http status: ', uploadToS3Resp.status)
	}
	// Wait for media to be added to the DB by media lambda
	// if we try to create the offer before the media record is added, the backend will return a 'media not found' error
	const delay = (s) => new Promise((res) => setTimeout(res, s * 1000))
	const maxRetries = 8
	const secondsBetweenRetries = 5
	let requestsSentCount = 0
	let mediaUrl = null
	let getMediaResp
	while (true) {
		// wait
		console.debug('waiting for media...')
		await delay(secondsBetweenRetries)
		// check for media
		getMediaResp = await getMediaRequest(mediaId)
		if (getMediaResp.status === 200) {
			mediaUrl = getMediaResp.data.url
			console.debug('media found')
			// stop checking if media found
			break
		}
		// check counter
		requestsSentCount++
		if (requestsSentCount > maxRetries) {
			console.log(`media not found after ${maxRetries * secondsBetweenRetries} seconds`)
			return null
		}
	}
	getMediaResp.data.mediaId = mediaId
	getMediaResp.data.mediaUrl = mediaUrl
	return getMediaResp.data
}
