import React, {createRef, Fragment, useState} from 'react';
import MapControl from "../MapControl/MapControl";
import RemoveAreaButton from "../Buttons/RemoveAreaButton";
import NewAreaButton from "../Buttons/NewAreaButton";
import {DrawingManager, Marker, Polygon} from "@react-google-maps/api";
import RemoveAreaIcon from "../../../../Shared/UI/assets/delete_area.svg";
import {DRAWING_MANAGER_OPTIONS, REMOVE_MARKER_ANCHOR} from "../../constants";
import {v4} from "uuid";


const getRemoveMarkerCoordinates = (start, end, lambda = 1 / 3) => {
    return (start + end * lambda) / (1 + lambda)
}

const Polygons = ({polygons, setPolygons}) => {
    const [drawingManager, setDrawingManager] = useState(null)
    const [isShowDeleteMarker, setIsShowDeleteMarker] = useState(true)

    const onLoadDrawingManager = drawingManager => {
        setDrawingManager(drawingManager)
    }

    const onPolygonComplete = (polygon) => {
        const id = v4()
        const ref = createRef()
        ref.current = polygon
        setPolygons(prev => [...prev, {id, polygonRef: ref, path: getPathFromPolygon(polygon)}])
        polygon.setMap(null)
        drawingManager.setDrawingMode(null)
    }

    const onEditPolygon = (event, id) => {
        const indexCurrentPolygon = polygons.findIndex(p => p.id === id)
        const currentPolygon = polygons[indexCurrentPolygon]
        currentPolygon.path = getPathFromPolygon(currentPolygon.polygonRef.current)
        setPolygons(prev => {
            prev[indexCurrentPolygon] = currentPolygon
            return [...prev]
        })
    }

    const onLoadPolygon = (polygon, id) => {
        const path = polygon.getPath();
        const listenersRef = createRef()
        const listenersArray = []
        listenersArray.push(
            path.addListener("set_at", (e) => onEditPolygon(e, id)),
            path.addListener("insert_at", (e) => onEditPolygon(e, id)),
            path.addListener("remove_at", (e) => onEditPolygon(e, id))
        );
        listenersRef.current = listenersArray
        const currentPolygon = polygons.find(p => p.id === id)
        currentPolygon.polygonRef.current = polygon
        currentPolygon.listenersRef = listenersRef
    }

    const onUnmount = (polygon, id) => {
        const currentPolygon = polygons.find(p => p.id === id)
        currentPolygon.listenersRef.current.forEach(lis => lis.remove());
    }

    const getPathFromPolygon = (polygon) => {
        return polygon.getPath()
            .getArray()
            .map(latLng => {
                return {lat: latLng.lat(), lng: latLng.lng()};
            })
    }

    const handleNewAreaClick = () => {
        drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
    }

    const handleRemovePolygon = (id) => () => {
        setPolygons(prev => {
            return prev.filter(p => p.id !== id)
        })
    }

    const isPolygonExist = !!polygons.length


    return (
        <>
            <MapControl position="LEFT_TOP">
                {
                    polygons.map((item, index) =>
                        <RemoveAreaButton
                            key={item.id}
                            text={`Area ${index + 1}`}
                            onClick={handleRemovePolygon(item.id)}
                        />
                    )
                }
                <NewAreaButton
                    text={isPolygonExist ? 'Add area' : 'Draw your own area'}
                    onClick={handleNewAreaClick}
                />
            </MapControl>
            {
                polygons.map(polygon => {
                    const removeMarkerPosition = {
                        lat: getRemoveMarkerCoordinates(polygon?.path[0].lat, polygon?.path[1].lat),
                        lng: getRemoveMarkerCoordinates(polygon?.path[0].lng, polygon?.path[1].lng),
                    }
                    return (
                        <Fragment key={polygon?.id}>
                            <Polygon
                                editable
                                draggable
                                options={DRAWING_MANAGER_OPTIONS}
                                path={polygon?.path}
                                onMouseUp={(e) => onEditPolygon(e, polygon.id)}
                                onDragEnd={(e) => {
                                    onEditPolygon(e, polygon.id)
                                    setIsShowDeleteMarker(true)
                                }}
                                onDragStart={() => {
                                    setIsShowDeleteMarker(false)
                                }}
                                onLoad={(e) => onLoadPolygon(e, polygon.id)}
                                onUnmount={(e) => onUnmount(e, polygon.id)}
                            />
                            {
                                isShowDeleteMarker &&
                                <Marker
                                    position={removeMarkerPosition}
                                    icon={{
                                        url: RemoveAreaIcon,
                                        anchor: REMOVE_MARKER_ANCHOR,
                                    }}
                                    onClick={handleRemovePolygon(polygon?.id)}
                                />
                            }
                        </Fragment>
                    )
                })
            }
            <DrawingManager
                onLoad={onLoadDrawingManager}
                onPolygonComplete={onPolygonComplete}
                options={DRAWING_MANAGER_OPTIONS}
            />
        </>
    );
};

export default Polygons;