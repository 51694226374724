import {mapStyles} from "./mapStlyles";

export const GOOGLE_LIBRARIES = ["drawing"]

export const MAP_CONTAINER_STYLE = {
    width: '100%',
    height: '100%'
}

export const GOOGLE_MAP_OPTIONS = {
    styles: mapStyles,
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true,
}

export const MAIN_MARKER_ANCHOR = {
    x: 45,
    y: 45
}

export const REMOVE_MARKER_ANCHOR = {
    x: 10,
    y: 10
}

export const DRAWING_MANAGER_OPTIONS = {
    drawingControl: false,
    polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: '#303141',
    }
}