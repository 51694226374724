import React, {useEffect, useState} from 'react';
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import UserListItem from "@sendbird/uikit-react/ui/UserListItem";
import ReactDOM from "react-dom";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import Button from "@sendbird/uikit-react/ui/Button";
import Label from "@sendbird/uikit-react/ui/Label";

const isAboutSame = (a, b, px) => (Math.abs(a - b) <= px);

const CustomInviteUsersModal = ({channel, forceUpdate}) => {
    const [search, setSearch] = useState('')
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState({});
    const disabledSubmit = !Object.keys(selectedMembers).length
    const [userQuery, setUserQuery] = useState(null);
    const [header, setHeader] = useState(null)
    const [footer, setFooter] = useState(null)
    const [closeElement, setCloseElement] = useState(null)

    const state = useSendbirdStateContext();
    const sdk = state?.stores?.sdkStore?.sdk;
    const globalUserListQuery = state?.config?.userListQuery;


    useEffect(() => {
        const userListQuery = globalUserListQuery ? globalUserListQuery() : sdk?.createApplicationUserListQuery({
            nicknameStartsWithFilter: search
        });
        if (userListQuery?.next) {
            userListQuery.next().then((members) => {
                setMembers(members);
            });
            setUserQuery(userListQuery);
        }
// eslint-disable-next-line
    }, [sdk, search]);

    useEffect(() => {
        const header = document.querySelector('.sendbird-modal__header')
        setHeader(header)
        const footer = document.querySelector('.sendbird-modal__footer')
        footer.innerHTML = ''
        setFooter(footer)
        const closeElement = document.querySelector('.sendbird-modal__close .sendbird-iconbutton__inner')
        setCloseElement(closeElement)
        return ()=>{
            setSearch('')
        }
    }, [])

    const handleSearchChange = (event) => {
        setSearch(event.target.value)
    }

    const handleCancelClick = () => {
        closeElement?.click()
    }

    const handleSubmitClick = () => {
        const members = Object.keys(selectedMembers).filter((m) => selectedMembers[m]);
        channel?.inviteWithUserIds(members).then(()=>{
            forceUpdate(Object.keys(selectedMembers))
        })
        closeElement?.click()
    }

    return (
        <>
            {
                header &&
                ReactDOM.createPortal(
                        <Input
                            styles={{marginTop:8, marginBottom:8}}
                            autoFocus
                            value={search}
                            onChange={handleSearchChange}
                        />,
                    header
                )
            }
            {
                footer &&
                ReactDOM.createPortal(
                    <>
                        <Button type={'SECONDARY'} onClick={handleCancelClick}>
                            <Label type={'BUTTON_1'} color={'ONBACKGROUND_1'}>
                                Cancel
                            </Label>
                        </Button>
                        <Button type={disabledSubmit ? 'DISABLED' : 'PRIMARY' } disabled={disabledSubmit} onClick={handleSubmitClick}>
                            Submit
                        </Button>
                    </>,
                    footer
                )
            }
            <div
                className="sendbird-more-members__popup-scroll"
                onScroll={(e) => {
                    const {hasNext} = userQuery;
                    const target = e.target;
                    const fetchMore = isAboutSame(target.clientHeight + target.scrollTop, target.scrollHeight, 20);
                    if (hasNext && fetchMore) {
                        userQuery.next().then((users) => {
                            setMembers([
                                ...members,
                                ...users,
                            ])
                        });
                    }
                }}
            >
                <div className="sendbird-more-members__popup-scroll__inner">
                    {members.map((member) => (
                        <UserListItem
                            checkBox
                            checked={selectedMembers[member.userId]}
                            onChange={
                                (event) => {
                                    const modifiedSelectedMembers = {
                                        ...selectedMembers,
                                        [event.target.id]: event.target.checked,
                                    };
                                    if (!event.target.checked) {
                                        delete modifiedSelectedMembers[event.target.id];
                                    }
                                    setSelectedMembers(modifiedSelectedMembers);
                                }
                            }
                            user={member}
                            key={member.userId}
                        />
                    ))}
                </div>
            </div>
        </>

    );
};

export default CustomInviteUsersModal;