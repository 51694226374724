import React from 'react';
import classes from "./DraggableIndicator.module.scss";
import Draggable from "./Draggable";
import {useSwipe} from "../../Shared/hooks/useDraggable";

const SwipeWrapper = ({className, handler, children}) => {
    const {touchHandlers, translate} = useSwipe({onSwipe: handler})

    return (
        <div
            className={`${classes.DraggableContainer} ${className ?? ''}`}
            style={{
                transform: `translateY(${translate.y}px`
            }}
        >
            <div className={classes.IndicatorContainer}>
                <Draggable
                    {...touchHandlers}
                >
                    <span className={classes.Indicator}/>
                </Draggable>
            </div>
            {children}
        </div>
    );
};

export default SwipeWrapper;