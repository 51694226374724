import React, {useState} from 'react';
import classes from './DatePicker.module.scss'
import {getMonth, getYear} from "date-fns";
import {range} from "lodash";
import {ReactComponent as BackIcon} from "../../../Shared/UI/assets/back.svg";
import {ReactComponent as DownIcon} from "../../../Shared/UI/assets/down.svg";
import StyledPicker from "../StyledPicker/StyledPicker";

const DatePickerNew = (
    {
        value, placeholder, className, onChange,
        minDate, maxDate, format,
        ...rest
    }) => {
    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const [isOpenYears, setIsOpenYears] = useState(false)

    const handleDownIconClick = () => {
        setIsOpenYears(!isOpenYears)
    }

    const renderHeader = ({
        date,
        changeYear,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div
            className={classes.CalendarHeader}
        >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <BackIcon/>
            </button>
            <div className={classes.MonthAndYear}>
                <div className={'react-datepicker__current-month'}>
                    {months[getMonth(date)]} {getYear(date)}
                </div>
                <DownIcon className={classes.DownIcon}  onClick={handleDownIconClick}/>
                {
                    isOpenYears &&
                        <div className={classes.YearsContainer}>
                            {
                                years.map((year) => (
                                    <div
                                        className={classes.Year}
                                        key={year}
                                        value={year}
                                        onClick={( event ) => {
                                            setIsOpenYears(false)
                                            changeYear(event.target.innerText)
                                        }}
                                    >
                                        {year}
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <BackIcon style={{transform:'rotate(180deg)'}}/>
            </button>
        </div>
    )

    const cls = [classes.DatePickerContainer]
    if (className) cls.push(className)

    return (
        <div className={cls.join(' ')}>
            <StyledPicker
                renderCustomHeader={renderHeader}
                selected={value}
                minDate={minDate}
                maxDate={maxDate}
                onChange={onChange}
                dateFormat={format || 'dd.MM.yyyy'}
                placeholderText={placeholder}
                {...rest}
            />
        </div>
    );
};

export default DatePickerNew;