import React from 'react';
import classes from "./EditBusiness.module.scss";
import OfferScheduleCustom from "../Offer/OfferScheduleCustom";

const Schedule = (
    {
        business, setHours, timePickerClass
    }) => {
    return (
        <div className={classes.Section}>
            <span className={classes.SubTitle}>Opening hours</span>
            <OfferScheduleCustom
                offer={business}
                updateOffer={setHours}
                timePickerClass={timePickerClass}
            />
        </div>
    );
};

export default Schedule;