import React from 'react';
import classes from "./Buttons.module.scss";
import {ReactComponent as RemoveIcon} from "../../../../Shared/UI/assets/closeIcon.svg";

const RemoveAreaButton = ({text, onClick}) => {
    return (
        <button
            className={classes.RemoveAreaButton}
            type={'button'}
            onClick={onClick}
        >
            {text}
            <RemoveIcon width={12} height={12}/>
        </button>
    );
};

export default RemoveAreaButton;