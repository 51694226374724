// === MEDIA S3 API ===
import axios from "axios";

export const mainUploadMediaToS3 = (presignedUrl, headers, data) => {
    // S3 PUT request
    const axiosInstance = axios.create()
    console.debug('starting media upload...')
    console.debug('url: ', presignedUrl)
    console.debug('headers: ', headers)
    return  axiosInstance.put(presignedUrl, data, {
        headers: headers,
    })
}
