import React from 'react';
import classes from './Buttons.module.scss'

const NewAreaButton = ({text, onClick}) => {
    return (
        <button
            className={classes.NewAreaButton}
            type={'button'}
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default NewAreaButton;