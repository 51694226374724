import React from 'react'
import OfferScheduleCustomDay from './OfferScheduleCustomDay'

import classes from './OfferScheduleCustom.module.css'

const OfferScheduleCustom = (props) => {
  return (
    <ul className={classes.DaysList}>
      {[...Array(7).keys()].map((i) => (
        <OfferScheduleCustomDay key={i} weekday={i} {...props} />
      ))}
    </ul>
  )
}

export default OfferScheduleCustom
