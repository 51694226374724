import React from 'react';

const Margin = ({top, bottom, children}) => {
    return (
        <div
            style={{
                marginTop: top,
                marginBottom: bottom
            }}
        >
            {children}
        </div>
    );
};

export default Margin;