import React from 'react';
import classes from './Preloader.module.css'

export const FullscreenPreloader = ({overlayClassName, spinnerClassName}) => {
    return (
        <section className={overlayClassName} onClick={(event)=>{
            event.preventDefault()
            event.stopPropagation()
        }}>
            <Preloader className={spinnerClassName}/>
        </section>
    );
};

export const Preloader = ({className}) => {
    return(
        <div className={`${classes.skCircleBounce} ${className}`} >
            <div className={`${classes.skChild}`}/>
            <div className={`${classes.skChild} ${classes.skCircle2}`}/>
            <div className={`${classes.skChild} ${classes.skCircle3}`}/>
            <div className={`${classes.skChild} ${classes.skCircle4}`}/>
            <div className={`${classes.skChild} ${classes.skCircle5}`}/>
            <div className={`${classes.skChild} ${classes.skCircle6}`}/>
            <div className={`${classes.skChild} ${classes.skCircle7}`}/>
            <div className={`${classes.skChild} ${classes.skCircle8}`}/>
            <div className={`${classes.skChild} ${classes.skCircle9}`}/>
            <div className={`${classes.skChild} ${classes.skCircle10}`}/>
            <div className={`${classes.skChild} ${classes.skCircle11}`}/>
            <div className={`${classes.skChild} ${classes.skCircle12}`}/>
        </div>
    )
}
