import React from 'react';
import Message from "../Message/Message";
import classes from './Messages.module.scss'

const Messages = ({messages}) => {
    return (
        <div className={classes.MessagesContainer}>
            {
                messages.map((message, index)=>(
                    <Message
                        key={message.message + index}
                        message={message.message}
                        type={message.type}
                        className={message.className}
                        handler={()=>{}}
                    >
                        {message.children}
                    </Message>
                ))
            }
        </div>
    );
};

export default Messages;