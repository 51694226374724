import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button, TextButton } from '../Components/Button/Buttons'
import ApiClient from '../Api/client'
import SuccessImg from './UI/assets/successIcon.svg'
import { ROUTES } from '../Shared/constants'
import { regexEmail } from '../Shared/utility/emailValidation'
import { regexPhone } from '../Shared/utility/phoneNumberValidation'
import classes from '../Auth/Auth.module.scss'
import forgotPasswordClasses from './ForgotPassword.module.scss'
import Input from '../Components/Input/Input'
import { yupResolver } from '@hookform/resolvers/yup'

const ForgotPassword = ({ logo, isDisabledLoginByPhone }) => {
  const api = new ApiClient()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({})
  const [step, setStep] = useState(1)
  const [codeIsIncorrect, setCodeIsIncorrect] = useState(false)
  const schema = yup.object().shape({
    login: yup
      .string(isDisabledLoginByPhone ? 'Enter your Email' : 'Enter your Email/Phone Number')
      .required(isDisabledLoginByPhone ? 'Email is required' : 'Email/Phone Number is required')
      .test('test-name', isDisabledLoginByPhone ? 'Enter Valid Email' : 'Enter Valid Phone/Email', function (value) {
        let isValidEmail = regexEmail.test(value)
        let isValidPhone = regexPhone.test(value)
        return !(!isValidEmail && !isValidPhone)
      }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const schemaForStep2 = yup.object().shape({
    password: yup.string().min(6, 'Password must be exactly 6 characters').required('Password is a required field'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords don’t match'),
    code: yup.number().typeError('The code should be a number').required('Code is a required field'),
  })

  const useFormStep2 = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schemaForStep2),
  })
  const registerStep2 = useFormStep2.register
  const errorsStep2 = useFormStep2.formState.errors
  const handleSubmitStep2 = useFormStep2.handleSubmit

  const forgotPassword = (login, onSuccess, onFailure) => {
    api.forgotPassword(login, onSuccess, onFailure)
  }

  const form1Submit = (data) => {
    forgotPassword(
      data.login,
      (d) => {
        console.log(d)
        setFormData(data)
        setStep(step + 1)
      },
      (d) => {
        console.error(d)
      }
    )
  }

  const form2Submit = (data) => {
    api.confirmPassword(
      data.code,
      data.password,
      formData.login,
      (data) => {
        setStep(step + 1)
      },
      (err) => {
        setCodeIsIncorrect(true)
        console.error(err)
      }
    )
  }
  const formStep1 = (
    <form className={classes.AuthForm} onSubmit={handleSubmit(form1Submit)}>
      <label className={classes.FormTitle}>Forgot password?</label>
      <p className={forgotPasswordClasses.Subtitle}>
        {isDisabledLoginByPhone &&
          "Don't worry we've got you covered. Enter your email and we’ll send a confirmation code."}
        {!isDisabledLoginByPhone &&
          "Don't worry we've got you covered. Enter your email or phone number and we’ll send a confirmation code."}
      </p>
      <Input
        register={register('login')}
        label={isDisabledLoginByPhone ? 'Email' : 'Phone number or email'}
        type='text'
      />
      <span className={forgotPasswordClasses.Error}>{errors.login?.message}</span>
      <Button className={forgotPasswordClasses.Button}>Send reset code</Button>
      <Link className={forgotPasswordClasses.Link} to={'/auth'}>
        Back to log in
      </Link>
    </form>
  )

  const handleResendCodeClick = () => {
    forgotPassword(
      formData.login,
      (d) => {
        console.log(d)
      },
      (d) => {
        console.error(d)
      }
    )
  }

  const formStep2 = (
    <form className={classes.AuthForm} onSubmit={handleSubmitStep2(form2Submit)}>
      {errorsStep2.confirmPassword?.message ||
      errorsStep2.password?.message ||
      errorsStep2.code?.message ||
      codeIsIncorrect ? (
        <label className={`${classes.FormTitle} ${forgotPasswordClasses.FormTitleError}`}>
          {errorsStep2.confirmPassword?.message ||
            errorsStep2.password?.message ||
            errorsStep2.code?.message ||
            'Code is incorrect'}
        </label>
      ) : (
        <label className={classes.FormTitle}>New password?</label>
      )}

      <Input
        containerClassName={classes.Input}
        register={registerStep2('password')}
        label={'Password'}
        type='password'
        error={errorsStep2.password?.message || errorsStep2.confirmPassword?.message}
      />
      <Input
        containerClassName={classes.Input}
        register={registerStep2('confirmPassword')}
        type='password'
        label={'Confirm password'}
        error={errorsStep2.password?.message || errorsStep2.confirmPassword?.message}
      />
      <Input
        containerClassName={classes.Input}
        label={'Confirmation code'}
        register={registerStep2('code')}
        type='text'
        error={codeIsIncorrect || errorsStep2.code?.message}
      />
      <Button className={forgotPasswordClasses.Button}>Set new password</Button>
      {codeIsIncorrect && (
        <TextButton className={forgotPasswordClasses.TextButton} onClick={handleResendCodeClick}>
          Resend code
        </TextButton>
      )}
      <Link className={forgotPasswordClasses.Link} to={'/auth'}>
        Back to log in
      </Link>
    </form>
  )

  const endOfForm = (
    <div className={classes.AuthForm}>
      <img className={forgotPasswordClasses.SuccessImg} src={SuccessImg} alt='' />
      <p className={forgotPasswordClasses.SuccessText}>Your password has been successfully changed</p>
      <Button
        className={forgotPasswordClasses.Button}
        onClick={() => {
          navigate(ROUTES.AUTH.AUTH, { replace: true })
        }}>
        Log in
      </Button>
    </div>
  )
  let formStep
  switch (step) {
    case 1:
      formStep = formStep1
      break
    case 2:
      formStep = formStep2
      break
    case 3:
      formStep = endOfForm
      break
    default:
      formStep = formStep1
  }

  return (
    <>
      <img src={logo} alt='' className={classes.NaooBusinessLogo} />
      {formStep}
    </>
  )
}

export default ForgotPassword
