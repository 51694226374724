import React from 'react';
import classes from './BusinessInfo.module.scss'
import {ReactComponent as LocationIcon} from "../../Shared/UI/assets/location-icon.svg";
import Avatar from "../../Components/Avatar/Avatar";
import {formatDistanceToNow} from "date-fns";

const BusinessInfo = ({business, createdAt}) => {

    const address = business?.address?.length > 20 ?
        business?.address.split(',')[0] : business?.address

    return (
       <div className={classes.Container} data-testid={'businessInfo'}>
           <Avatar
                img={business?.logo?.url}
                name={business?.name}
                className={classes.Logo}
           />
           <div className={classes.NameAndInfo}>
               <div className={classes.Name}><span>{business?.name}</span></div>
               <div className={classes.Info}>
                   <span>{formatDistanceToNow(new Date(createdAt), {addSuffix: true})}</span>
                   {
                       address &&
                           <>
                               <LocationIcon width={20} height={20}/>
                               <span>{address}</span>
                           </>
                   }
               </div>
           </div>
       </div>
    );
};

export default BusinessInfo;