import ApiClient from './client'

const apiClient = new ApiClient()

export const createRequest = (api, method, url, body, params) => {
    // select main or admin api
    let axiosInstance = undefined
    switch (api) {
        case 'main':
            axiosInstance = apiClient.main
            break
        case 'admin':
            axiosInstance = apiClient.admin
            break
        default:
            console.error('api must be main or admin')
            return undefined
    }
    // create request
    let req = undefined
    if (!method) {
        console.error('method must be provided')
        return undefined
    }
    switch (method.toLowerCase()) {
        case 'get':
            req = axiosInstance.get(url, {params} )
            break
        case 'post':
            req = axiosInstance.post(url, body)
            break
        case 'patch':
            req = axiosInstance.patch(url, body)
            break
        case 'delete':
            req = axiosInstance.delete(url)
            break
        default:
            console.error('unknown http method')
            return undefined
    }
    return req
}
