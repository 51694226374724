import React from 'react';
import {ReactComponent as Check} from '../../Shared/UI/assets/check.svg'
import classes from './CheckBox.module.scss'


const CheckBox = React.forwardRef(({ label, isChecked, onChange, disabled, register, ...props }, ref) => {
    return (
        <label className={classes.CheckboxLabel}>
            <input
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                disabled={disabled}
                ref={ref}
                {...register}
                {...props}
            />
            <span className={classes.CheckboxBox}>
                <Check width={16} height={16}/>
            </span>
            {
                label && <span className={classes.CheckboxText}>{label}</span>
            }
        </label>
    );
});

export default CheckBox;