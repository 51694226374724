import React from 'react';
import classes from './Dropzone.module.scss'
import {useDropzone} from "react-dropzone";
import DropzonePlaceholder from "./DropzonePlaceholder/DropzonePlaceholder";
import {ReactComponent as ClearIcon} from "../../Shared/UI/assets/closeIcon.svg";
import {executeIfExist} from "../../Shared/utility/utils";

const Dropzone = (
    {
        media, onChange, Placeholder, onClear, className,
        onDropAnalytics, onClickAnalytics, label,
        ...props
    }) => {

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: files => onChange(files),
        ...props,
    })

    const handleClearClick = (event) => {
        event.stopPropagation()
        onClear()
    }

    const handleOnDrop = () => {
        executeIfExist(onDropAnalytics)
    }

    const handleOnClick = () => {
        executeIfExist(onClickAnalytics)
    }

    return (
        <div
            className={`${classes.Container} ${className ?? ''} ${!media ? classes.Border : ''}`}
            onDrop={handleOnDrop}
            onClick={handleOnClick}
            {...getRootProps()}
        >
            {
                media
                    ? <video
                        className={classes.Media}
                        src={media}
                        autoPlay={true}
                        muted={true}
                        poster={media}
                        loop={true}
                    />
                    : <div className={`${classes.PlaceholderContainer} ${ isDragActive ? classes.DropzoneActive : ''}`}>
                        {  Placeholder ?  <Placeholder/> : <DropzonePlaceholder label={label}/> }
                    </div>
            }
            {
                (onClear && media) &&
                <div className={classes.ClearIconContainer} onClick={handleClearClick}>
                    <ClearIcon width={16} height={16} className={classes.ClearIcon}/>
                </div>
            }
            <input {...getInputProps()} />
        </div>
    );
};

export default Dropzone;