import React from 'react';
import {Navigation, Pagination} from "swiper";
import classes from './Slider.module.scss'
import SlidePrevButton from "./SlidePrevButton";
import SlideNextButton from "./SlideNextButton";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Slider = ({media, isShowVideoControls}) => {
    return (
        <Swiper
            className={classes.Swiper}
            modules={[Navigation, Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{clickable: true}}
        >
            {
                media.length > 1 &&
                <>
                    <SlidePrevButton/>
                    <SlideNextButton/>
                </>
            }

            {
                media.map((item) => <SwiperSlide key={item.id}>
                    {
                        item?.contentType?.includes('video')
                            ? <video
                                className={classes.Img}
                                src={item.url}
                                autoPlay={true}
                                muted={true}
                                loop={true}
                                controls={isShowVideoControls}
                            />
                            : <img
                                className={classes.Img}
                                src={item.url}
                                alt={''}
                            />
                    }

                </SwiperSlide>)
            }
        </Swiper>
    );
};

export default Slider;