import React from 'react'
import classes from './Input.module.scss'
import {ReactComponent as CopyIcon} from "../../Shared/UI/assets/copy.svg";

const Input = React.forwardRef(
    ({label, name, type, onChange, placeholder, value, error, maxLength, styles, className, containerClassName, Icon, copy, readOnly, register, ...props}, ref) => {
        return (
            <>
                {label && <label className={classes.Label}>{label}</label>}
                <div className={`${classes.Container}  ${containerClassName ?? ''}`}>
                    <input
                        ref={ref}
                        className={`${classes.Input} ${error && classes.InputError} 
                                    ${maxLength && classes.InputLength} ${className ?? ''}`}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        maxLength={maxLength}
                        style={styles}
                        readOnly={readOnly}
                        {...register}
                        {...props}
                    />
                    {maxLength && !Icon && !copy && <span className={classes.Length}>{value?.length ?? props?.length}/{maxLength}</span>}
                    {
                        Icon && !copy && <Icon/>
                    }
                    {
                        copy && <CopyIcon
                            className={classes.CopyIcon}
                            onClick={()=>{
                                navigator.clipboard.writeText(value)
                            }}/>
                    }

                </div>
            </>
        )
    })

export default Input
