import React from 'react';
import classes from "./DropzonePlaceholder.module.scss";
import {ReactComponent as CameraIcon} from "../../../Shared/UI/assets/camera-icon.svg";

const DropzonePlaceholder = ({label}) => {
    return (
        <div className={classes.Container}>
            <CameraIcon/>
            <span className={classes.ImageDropzoneHintLine1}>
                {label ?? 'Drag images here'}
            </span>
            <span className={classes.ImageDropzoneHintLine2}>or</span>
            <span className={classes.ImageDropzoneHintLine3}>Browse</span>
        </div>
    );
};

export default DropzonePlaceholder;