import React from 'react'
import classes from './OfferPreview.module.css'
import LocationIcon from '../Shared/UI/assets/location-icon.svg'
import CommentIcon from '../Shared/UI/assets/comment_Icon.svg'
import SharedIcon from '../Shared/UI/assets/shared.svg'
import NaooLogo from '../Shared/UI/assets/naooIcon.svg'
import {ReactComponent as CameraIcon} from '../Shared/UI/assets/camera-icon.svg'
import OfferPreviewPriceAndDiscount from './OfferPreviewPriceAndDiscount'
import {ReactComponent as LikeCheckbox} from "../Shared/UI/assets/like.svg";
import {BUTTON_TYPES, Button} from "../Components/Button/Buttons";
import {formatDistanceToNow} from "date-fns";

const OfferPreview = ({offer}) => {

    const address = offer?.business?.address?.length > 20 ?
        offer?.business?.address.split(',')[0] : offer?.business?.address

    const businessLogo = offer?.business?.media?.[0].url ? (
        <img className={classes.BusinessLogo} alt="" src={offer?.business?.media?.[0].url}/>
    ) : (
        <span className={classes.FirstLetter}>
      {offer?.business?.name ? offer?.business?.name.toUpperCase()[0] : ''}
    </span>
    )
    const businessName = (
        <span className={classes.BusinessName}>{offer?.business?.name}</span>
    )
    const offerTime = offer?.createdAt
        ? <span className={classes.OfferTime}>{formatDistanceToNow(new Date( offer?.createdAt),{ addSuffix:true})}</span>
        : null
    const locationIcon = (
        <img className={classes.LocationIcon} alt="" src={LocationIcon}/>
    )
    const offerLocation = <span className={classes.OfferLocation}>{address}</span>
    const offerImage = (
            offer?.media?.[0]?.url
            ? <img
                className={classes.OfferImage}
                alt=""
                src={offer?.media?.[0]?.url}
              />
            : <div className={classes.ImagePlaceholder}>
                    <CameraIcon width={24} height={24}/>
              </div>
    )
    const priceAndDiscount = (
        <OfferPreviewPriceAndDiscount
            price={offer?.price}
            discount={offer?.price && offer?.discountedPrice
                ? offer?.price - offer?.discountedPrice
                : null}
            discountPercent={
                offer?.price && offer?.discountedPrice
                    ? ((offer.price - offer.discountedPrice) / offer?.price * 100).toFixed(0)
                    : null
            }
        />
    )

    const coins =  offer?.points ?(
        <div className={classes.CoinsContainer}>
            <span className={classes.CoinsText}>{offer?.points}</span>
            <img src={NaooLogo} alt=""/>
        </div>
    ) : null

    const offerTitle = <p className={classes.OfferTitle}>{offer.title ? offer.title : 'Offer Title'}</p>
    const offerText = (
        <p className={classes.OfferDescription}>{offer.text
            ? offer.text
            : 'Describe your offer'}</p>
    )

    return (
        <div className={classes.OfferPreview}>
            <div className={classes.OfferPreviewHeader}>
                {businessLogo}
                <div style={{marginLeft:10}}>
                    <div>
                        {businessName}
                    </div>
                    <div style={{display:"flex",alignItems:"center"}}>
                        {offerTime}
                        {locationIcon}
                        {offerLocation}
                    </div>
                </div>
            </div>
            <div className={classes.OfferPreviewImageContainer}>
                {coins}
                {offerImage}
                {priceAndDiscount}
            </div>
            <div className={classes.ButtonsContainer}>
                <div className={classes.LikeContainer}>
                    <LikeCheckbox/>
                    <span className={classes.Number}>15</span>
                </div>
                <div className={classes.CommentsContainer}>
                    <img src={CommentIcon} alt=""/>
                    <span className={classes.Number}>5</span>
                </div>
                <img className={classes.SharedIcon} src={SharedIcon} alt=""/>
                <Button variant={BUTTON_TYPES.NOT_ROUNDED}>Claim</Button>
            </div>
            <div className={classes.TitleAndText}>
                {offerTitle}
                {offerText}
            </div>

        </div>
    )
}

export default OfferPreview
