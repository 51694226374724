import React from 'react'

import classes from './OfferSchedulePresetsDropdown.module.css'

const OfferSchedulePresetsDropdown = (props) => {
  if (!props.presets) {
    return null
  }
  return (
    <ul className={classes.PresetsDropdown}>
      {props.presets.map((p) => (
        <li key={p.name} onClick={() => props.onPresetSelected(p)}>
          <span>{p.name}</span>
        </li>
      ))}
    </ul>
  )
}

export default OfferSchedulePresetsDropdown
