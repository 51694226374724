import React from 'react';
import {ReactComponent as IconArrow} from "./icon-arrow-Left.svg";
import classes from './ChannelsListHeader.module.scss'
import {useNavigate} from "react-router-dom";
import CustomCreateChannelWrapper from "../CustomCreateChannelWrapper/CustomCreateChannelWrapper";

const ChannelsListHeader = () => {
    const navigate = useNavigate()

    const handleIconClick = () => {
        navigate(-1)
    }

    return (
        <div className={classes.Container}>
            <IconArrow className={classes.Icon} onClick={handleIconClick}/>
            <span className={'sendbird-label sendbird-label--h-2 sendbird-label--color-onbackground-1'}>Channels</span>
            <div className={classes.AddChannel}>
                <CustomCreateChannelWrapper/>
            </div>
        </div>
    );
};


export default ChannelsListHeader

//