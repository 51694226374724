import React from 'react'
import { NavLink } from 'react-router-dom'

import classes from './SidebarItem.module.scss'

const SidebarItem = ({title, to, disabled, onClick}) => {
  return (
    <NavLink
      className={({isActive})=>{
        let cls = [classes.SidebarItem]
        if (isActive) cls.push(classes.active)
        if (disabled) cls.push(classes.SidebarItemDisabled)
        return cls.join(' ')
      }}
      to={to}
      onClick={onClick}
    >
      {title}
    </NavLink>
  )
}

export default SidebarItem
