import React, {useEffect, useState} from 'react';
import {useOnClickOutside} from "../../Shared/hooks/useclickOutside";
import Arrow from "../../Shared/UI/assets/down.svg";
import classes from './CategoriesDropdown.module.css'
import CheckboxSelectedIcon from "./assets/checkBoxSelected.svg";
import CheckboxNotSelectedIcon from "./assets/CheckboxNotSelected.svg";

const CategoriesDropdown = ({list, selectedList, onChange, err, onClick}) => {
    const [isShow, setIsShow] = useState(false)
    const [categories, setCategories] = useState('')

    const ref = useOnClickOutside(() => {
        setIsShow(false)
    })

    useEffect(() => {
        let value = ''
        if (selectedList.length !== 0) {
            selectedList.forEach((item) => {
                value += item.name + ', '
            })
            value = value.slice(0, value.length - 2)
            setCategories(value)
        } else {
            setCategories('')
        }
    }, [selectedList])

    const handleCategoriesFieldClick = () => {
        onClick && onClick()
        setIsShow(!isShow)
    }

    const handleItemClick = (item) => () => {
        onChange(item)
    }

    return (
        <div className={classes.CategoryContainer} ref={ref}>
            <div
                className={classes.InputContainer}
                onClick={handleCategoriesFieldClick}
            >
                <input
                    className={`${classes.Input} ${selectedList?.length === 0 && classes.InputError}`}
                    name={'category'}
                    type="text"
                    placeholder={'Choose your categories'}
                    value={categories}
                    readOnly
                />
                <img src={Arrow} style={isShow ? {'transform': 'rotate(180deg) translateY(50%)'} : {}} alt=""/>
            </div>

            {
                isShow &&
                <div className={classes.DropdownContainer}>
                    {
                        list.map((item, index) => (
                            <div
                                key={index}
                                className={classes.DropdownItem}
                                onClick={handleItemClick(item)}
                            >
                                <img src={item.isSelected ? CheckboxSelectedIcon : CheckboxNotSelectedIcon} alt=""/>
                                {item.name}
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
};

export default CategoriesDropdown;
