import React from 'react';
import classes from "./TimePickerNew.module.scss";
import {ReactComponent as DownIcon} from "../../../Shared/UI/assets/down.svg";
import StyledPicker from "../StyledPicker/StyledPicker";

const TimePickerNew = ({value, label, minTime, maxTime, onChange, className, ...rest}) => {
    const cls = [classes.TimePickerContainer]
    if (className) cls.push(className)

    return (
        <div className={cls.join(' ')}>
            {
                label && <span>{label}</span>
            }
            <StyledPicker
                popperClassName={classes.TimeDropdownContainer}
                className={classes.TimePickerInput}
                selected={value}
                onChange={onChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat={'HH:mm'}
                timeFormat="HH:mm"
                minTime={minTime}
                maxTime={maxTime}
                {...rest}
            />
            {
                ( !rest?.isClearable || !value  ) &&
                <DownIcon className={classes.DownIcon} width={16} height={16}/>
            }
        </div>
    );
};

export default TimePickerNew;