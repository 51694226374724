import {useCallback, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";

export const useObserver = (element, fetching, setFetching, nextToken, getByNextToken, rootElement) => {

    const dispatch = useDispatch()

    const handleOnFetching = useCallback(() => {
        if (nextToken) {
            dispatch(getByNextToken({
                nextToken: nextToken
            }))
        }
        else{
            dispatch(setFetching(false))
        }
    }, [dispatch, nextToken, setFetching, getByNextToken])

    const handleSetFetching = useCallback((entries) => {
        const first = entries[0]
        if (first.isIntersecting) {
            dispatch(setFetching(true))
        }
    }, [dispatch, setFetching])


    const observer = useRef(
        new IntersectionObserver(
            handleSetFetching,
            {
                threshold: 1,
                root: rootElement
            }))

    useEffect(()=>{
        const currentElement = element
        const currentObserver = observer.current
        if (currentElement){
            currentObserver.observe(currentElement)
        }
        return ()=>{
            if (currentElement){
                currentObserver.unobserve(currentElement)
            }
        }
    }, [element])

    useEffect(()=>{
        if (fetching){
            handleOnFetching()
        }
    },[fetching, handleOnFetching])
};
