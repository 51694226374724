import {useState} from "react";

export const useSwipe = (
    {
        onSwipe,
        swipeLength = 50,
        shouldReset = true,
    }) => {
    const [isDragging, setIsDragging] = useState(false)

    const [startPosition, setStartPosition] = useState({
        x: 0,
        y: 0
    })

    const [translate, setTranslate] = useState({
        x: 0,
        y: 0
    });


    const handleTouchMove = (event) => {
        setTranslate({
            x: event.targetTouches[0].pageX - startPosition.x,
            y: event.targetTouches[0].pageY - startPosition.y
        });
    }

    const handleTouchEnd = (event) => {
        setIsDragging(false)

        if (translate.y > swipeLength) {
            onSwipe()
        }

        if (shouldReset) {
            setTranslate({
                x: 0,
                y: 0
            });
        }
    }

    const handleTouchStart = (event) => {
        setIsDragging(true)
        setStartPosition({
            x: event.targetTouches[0].pageX,
            y: event.targetTouches[0].pageY
        })
    }

    return {
        isDragging,
        translate,
        touchHandlers: {
            onTouchStart: handleTouchStart,
            onTouchEnd: handleTouchEnd,
            onTouchMove: handleTouchMove,
        }
    }
}