import React from 'react';
import classes from "./Slider.module.scss";
import {ReactComponent as Back} from "../../Shared/UI/assets/back.svg";
import {useSwiper} from "swiper/react";

const SlideNextButton = () => {
    const swiper = useSwiper();
    const handleNextButtonClick = () => {
        swiper.slideNext()
    }
    return (
        <div className={`${classes.PrevButton} ${classes.NextButton}`} onClick={handleNextButtonClick}>
            <Back fill={'#303141'}/>
        </div>
    );
};

export default SlideNextButton;