import React from 'react';
import classes from './Avatar.module.scss'
import {ReactComponent as AvatarIcon} from "./avatar.svg";

const Avatar = ({ img, name, lastname, isOnline, className}) => {
    return (
        <div className={`${classes.Container} ${className}`}>
            {
                img
                    ? <img className={classes.Logo} src={img} alt=""/>
                    : <div className={classes.LogoLetter}>
                        {
                            name
                                ? <span>{name[0].toUpperCase()}{lastname?.[0]?.toUpperCase()}</span>
                                : <AvatarIcon/>
                        }
                    </div>
            }
        </div>
    );
};

export default Avatar;