import React from 'react';
import classes from "./Mention.module.scss";

const Mention = ({mention, id}) => {
    return (
        <span className={classes.Mention} data-id={id}>
            @{mention}
        </span>
    );
};

export default Mention;