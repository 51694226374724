import React, {useContext} from 'react'
import {useDispatch} from 'react-redux'
import classes from './OfferInfo.module.scss'
import {AnalyticsContext} from "../Shared/context/analyticsContext";
import {executeIfExist} from "../Shared/utility/utils";
import Dropzone from "../Components/Dropzone/Dropzone";

const OfferInfo = (props) => {
    const dispatch = useDispatch()
    const analyticsEvents = useContext(AnalyticsContext)

    const media = props?.media?.id ? props.media.url : props.media

    const handleDropZone = (acceptedFiles) => {
        dispatch(props.updateMedia({
            media: URL.createObjectURL(acceptedFiles[0])
        }))
    }

    const handleRemoveMedia = () => {
        executeIfExist(analyticsEvents?.image_dropzone_delete_btn_clicked)
        dispatch(props.updateMedia({
            media: ""
        }))
    }

    let pendingMediaInfo = null
    if (props.media) {
        pendingMediaInfo = (
            <div className={classes.PendingMediaInfo}>
                <button onClick={handleRemoveMedia}>
                    Remove image
                </button>
            </div>
        )
    }

    const handleOnDropAnalytics = () => {
        executeIfExist(analyticsEvents?.image_dropzone_drag_n_drop)
    }

    const handleOnClickAnalytics = () => {
        executeIfExist(analyticsEvents?.image_dropzone_clicked)
    }

    const offerImageSection = (
        props.offer.isAdminDashboard
            ? null
            : <>
                <span className={classes.OfferImageAreaTitle}>Image</span>
                <div className={classes.ImgInfoContainer}>
                    <Dropzone
                        media={media}
                        onChange={handleDropZone}
                        className={classes.Dropzone}
                        onDropAnalytics={handleOnDropAnalytics}
                        onClickAnalytics={handleOnClickAnalytics}
                    />
                    {pendingMediaInfo}
                </div>
              </>
    )

    const handleOfferTitleInputChange = (event) => {
        dispatch(props.updateOffer({
            title: event.target.value
        }))
    }

    const handleOfferDescriptionInputChange = (event) => {
        dispatch(props.updateOffer({
            text:  event.target.value
        }))
    }

    return (
        <div className={classes.CreateOfferInfo}>
            <span className={classes.Title}>Offer Title & Description</span>
            <span className={classes.OfferTitleLabel}>Title</span>
            <div className={classes.OfferTitleInputContainer}>
                <input
                    maxLength={80}
                    className={classes.OfferTitleInput}
                    placeholder="Offer title"
                    value={props.offer?.title}
                    onChange={handleOfferTitleInputChange}
                />
                <span className={classes.OfferTitleInputLengthCounter}>
                     {props.offer?.title ? props.offer.title.length : 0}/80
                </span>
            </div>

            <span className={classes.OfferDescriptionLabel}>Description</span>
            <textarea
                className={classes.OfferDescriptionInput}
                placeholder="Describe your offer"
                value={props.offer?.text}
                onChange={handleOfferDescriptionInputChange}
            />
            {offerImageSection}
        </div>
    )
}

export default OfferInfo
