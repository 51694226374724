import React, {useContext} from 'react'
import classes from './OfferSettingsClaimLimitOptionsList.module.scss'
import ListItemSelectedIcon from '../Shared/UI/assets/list-item-selected-icon.svg'
import ListItemNotSelectedIcon from '../Shared/UI/assets/list-item-not-selected-icon.svg'
import {AnalyticsContext} from "../Shared/context/analyticsContext";
import {executeIfExist} from "../Shared/utility/utils";

const DEFAULT_MAX_CLAIMS = 5

const OfferSettingsClaimLimitOptionsList = (props) => {
    const analyticsEvents = useContext(AnalyticsContext)

    const handleUnlimitedClaimsClick = () => {
        executeIfExist(analyticsEvents?.claiming_unlimited_btn_clicked)
        props.onOfferMaxClaimsSelected(0)
    }

    const handleCustomMaxClaimsClick = () => {
        executeIfExist(analyticsEvents?.claiming_custom_limit_btn_clicked)
        props.onOfferMaxClaimsSelected(DEFAULT_MAX_CLAIMS)
    }

    const handleCustomMaxClaimsInput = (event) => {
        props.onOfferMaxClaimsSelected(event.target.value)
    }

    return (
        <ul className={classes.Options}>
            <li onClick={handleUnlimitedClaimsClick}>
                <img
                    alt=""
                    src={props.claimsLimit ? ListItemNotSelectedIcon : ListItemSelectedIcon}
                    className={classes.OptionRadioButtonIcon}
                />
                <span className={classes.OptionText}>Unlimited</span>
            </li>
            <li onClick={handleCustomMaxClaimsClick}>
                <img
                    src={props.claimsLimit ? ListItemSelectedIcon : ListItemNotSelectedIcon}
                    alt=""
                    className={classes.OptionRadioButtonIcon}
                />
                <span className={classes.OptionText}>Custom</span>
                <input
                    className={classes.CustomMaxClaimsInput}
                    style={{
                        opacity: props.claimsLimit !== 0 ? 1.0 : 0.4,
                        pointerEvents: props.claimsLimit !== 0 ? 'all' : 'none',
                    }}
                    onChange={handleCustomMaxClaimsInput}
                    value={props.claimsLimit ? props.claimsLimit : ''}
                />
                <span className={classes.OptionText} onClick={handleCustomMaxClaimsClick}>{'times'}</span>
            </li>
        </ul>
    )
}

export default OfferSettingsClaimLimitOptionsList
