import React from 'react'

import IsSelectedIcon from '../Shared/UI/assets/list-item-selected-icon.svg'
import IsNotSelectedIcon from '../Shared/UI/assets/list-item-not-selected-icon.svg'

import classes from './OfferScheduleModeListItem.module.scss'

const OfferScheduleModeListItem = (props) => {
  return (
    <li className={classes.Container} onClick={props.onClick}>
      <img
        className={classes.Icon}
        alt=""
        src={props.isSelected ? IsSelectedIcon : IsNotSelectedIcon}
      />
      <span className={classes.Text}>{props.text}</span>
    </li>
  )
}

export default OfferScheduleModeListItem
