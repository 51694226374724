import React, {useEffect, useRef} from 'react';
import {useGoogleMap} from "@react-google-maps/api";

const MapControl = ({position, children}) => {
    const map = useGoogleMap();
    const ref = useRef();
    useEffect(() => {
        if (map && ref) {
            map.controls[window.google.maps.ControlPosition[position]].push(
                ref.current
            );
        }
    }, [map, ref, position]);
    return <div style={{display: "flex", marginTop: 12, marginLeft: 12}} ref={ref}>{children}</div>;
};

export default MapControl;