import React from 'react'

import classes from './OfferSchedulePresets.module.css'

import DropdownIcon from '../Shared/UI/assets/schedules-dropdown-icon.svg'

const OfferSchedulePresets = (props) => (
  <div className={classes.Container}>
    <span className={classes.Title}>{'Presets'}</span>
    <p className={classes.SelectedPreset} onClick={props.onClick}>
      {props.selectedPresetName}
      <img className={classes.DropdownIcon} src={DropdownIcon} alt="" />
    </p>
  </div>
)

export default OfferSchedulePresets
