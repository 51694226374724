import React from 'react';
import classes from "./DraggableIndicator.module.scss";

const Draggable = (
    {
        onTouchEnd,
        onTouchStart,
        onTouchMove,
        children,
        style,
        className,
    }) => {

    return (
        <div
            onTouchEnd={onTouchEnd}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            style={style}
            className={`${className} ${classes.DraggableContainer}`}
        >
            {children}
        </div>
    );
};

export default Draggable;

