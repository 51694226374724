import React from 'react';
import classes from './GalleryItem.module.scss'
import {ReactComponent as TimesIcon} from '../../../Shared/UI/assets/closeIcon.svg'

const GalleryItem = ({media, className, onDelete}) => {
    return (
        <div className={`${classes.Container} ${className ?? ''}`}>
            <div className={classes.TimesIcon} onClick={onDelete}>
                <TimesIcon width={16} height={16}/>
            </div>
            <video
                className={classes.Media}
                src={media}
                autoPlay={true}
                muted={true}
                loop={true}
                poster={media}
            />
        </div>
    );
};

export default GalleryItem;