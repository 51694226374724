import React, {useState} from 'react';
import {ReactComponent as DropdownIcon} from "../../Shared/UI/assets/down.svg";
import classes from './Dropdown.module.scss'
import {ReactComponent as ClearIcon} from "../../Shared/UI/assets/closeIcon.svg";
import {useOnClickOutside} from "../../Shared/hooks/useclickOutside";

const Dropdown = ({options, value, placeholder, className, onChange, isClearable= false}) => {
    const [isShow, setIsShow] = useState(false)

    const ref = useOnClickOutside(() => setIsShow(false))

    const handleClearIconClick = (event)=>{
        event.stopPropagation()
        onChange('')
    }

    const isObject = typeof options?.[0] === "object"

    return (
        <div ref={ref} className={`${classes.Container} ${className}`}>
            <div className={'InputContainer'} onClick={() => setIsShow(!isShow)}>
                <input
                    className={'Input'}
                    type="text"
                    readOnly
                    value={ value?.title || value || ''}
                    placeholder={placeholder}
                />
                {
                    (isClearable && value)
                        ? <ClearIcon onClick={handleClearIconClick}/>
                        : <DropdownIcon style={isShow ? {'transform': 'rotate(180deg)'} : {}}/>
                }
            </div>
            {
                isShow &&
                <div className={'OptionsContainer'}>
                    {options.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'Option'}
                                onClick={() => {
                                    setIsShow(!isShow)
                                    onChange( item )
                                }}
                            >
                                {isObject ? item.title : item}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    );
};

export default Dropdown;
