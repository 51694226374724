import React, {Fragment} from "react";
import Mention from "../../Components/Mention/Mention";

const SPLIT_PATTERN = /(?<business>\[<<.*?>>\]{<<.*?>>})|(?<user>\[\*\*.*?\*\*\]{\*\*.*?\*\*})|(?<mention><@.*?>)/
const BUSINESS_PATTERN = /(\[<<(?<name>.*?)>>\]{<<(?<id>.*?)>>})/
const USER_PATTERN = /(\[\*\*(?<name>.*?)\*\*\]{\*\*(?<id>.*?)\*\*})/
const MENTION_PATTERN =/(?<mention><@(?<id>.*?)>)/


export const useMentionData = (text, mentions ) => {

    const data = React.useMemo(() => {
        const parts = text.split(SPLIT_PATTERN);

        const convertPartToMetadata = (part) => {
            const businessMatch = part.match(BUSINESS_PATTERN)
            const userMatch = part.match(USER_PATTERN)
            const mentionMatch = part.match(MENTION_PATTERN)

            if (businessMatch) {
                return {
                    business: {
                        name: businessMatch.groups.name,
                        id: businessMatch.groups.id
                    }
                }
            }

            if (userMatch) {
                return {
                    user: {
                        name: userMatch.groups.name,
                        id: userMatch.groups.id
                    }
                }
            }

            if (mentionMatch) {
                const id = mentionMatch.groups.id
                return mentions?.find(item=>(item?.business?.id === id || item?.user?.id === id)) || {text: part}
            }

            return {text: part}
        };

        const transformText = (part) => {
            const businessMatch = part.match(BUSINESS_PATTERN)
            const userMatch = part.match(USER_PATTERN)

            if (businessMatch) return `<@${businessMatch.groups.id}>`
            if (userMatch) return `<@${userMatch.groups.id}>`
            return part
        }

        const metadata =  parts.filter((part) => part).map(convertPartToMetadata);
        const transformedText = parts.filter((part) => part).map(transformText).join('')

        return {
            metadata,
            transformedText
        }
    }, [text, mentions]);

    const getMentions = () => {
             return data.metadata.map((item)=>{
                    if(item?.business?.id){
                        return {
                            business : {id: item?.business?.id}
                        }
                    }

                    if(item?.user?.id){
                        return {
                            user : {id: item?.user?.id}
                        }
                    }
                    return null
                }).filter(item=>!!item)
    }

    const getTextWithMentions = () =>(
        data.metadata.map((item, index) => {
            return <Fragment key={item.toString() + index}>
                {
                    (item?.business?.id) &&
                    <Mention mention={item?.business?.name} id={item?.business?.id}/>
                }
                {
                    item?.user?.id &&
                    <Mention mention={`${item?.user?.firstname} ${item?.user?.lastname}`} id={item?.user?.id}/>
                }
                {
                    item?.text && item?.text
                }
            </Fragment>
        })
    )


    return {
        getMentions,
        getTextWithMentions,
        transformedText: data.transformedText
    }
}