import React, {useEffect, useMemo, useState} from 'react';
import classes from './PostStats.module.scss'
import ModalOverlay from "../../Components/ModalOverlay/ModalOverlay";
import Chart from "../../Components/Chart/Chart";
import {useDispatch, useSelector} from "react-redux";
import {getPostStatsThunk, selectActivePost, selectPostStats} from "../../../../store/reducers/postsReducer";
import PostInfo from "./PostInfo/PostInfo";
import DatePicker from "../../Components/Date/DatePicker/DatePicker";
import {add, sub} from "date-fns";
import SwipeWrapper from "../../Components/Draggable/SwipeWrapper";

const PostStats = ({onClose}) => {
    const dispatch = useDispatch()

    const [startDate, setStartDate] = useState(sub(new Date(), {days:7}))
    const [endDate, setEndDate] = useState(new Date())

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }
    const handleEndDateChange = (date) => {
        setEndDate(date)
    }

    const post = useSelector(selectActivePost)
    const stats = useSelector(selectPostStats)

    const totalImpression = useMemo(()=>{
        return  stats.reduce((total, current)=> total + current.impressionsTotal ,0)
    } ,[stats])

    useEffect(()=>{
        dispatch(getPostStatsThunk({
            id: post?.id,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString()
        }))
    }, [dispatch, startDate, endDate, post.id])

    return (
        <ModalOverlay onClose={onClose}>
            <SwipeWrapper handler={onClose}>
                <div className={classes.Container}>
                    <div className={classes.PreviewContainer}>
                        <PostInfo post={post}/>
                    </div>

                    <div className={classes.StatisticsContainer}>
                        <div className={classes.Header}>
                            <div className={classes.TimeframeStart}>
                                <div className={classes.Label}>
                                    <span>Timeframe start:</span>
                                </div>
                                <DatePicker
                                    className={classes.Timeframe}
                                    value={startDate}
                                    minDate={sub(endDate, {days: 89})}
                                    maxDate={endDate}
                                    format={'dd.MM.yy'}
                                    onChange={handleStartDateChange}
                                />
                            </div>
                            <div className={classes.TimeframeEnd}>
                                <div className={classes.Label}>
                                    <span>Timeframe end:</span>
                                </div>
                                <DatePicker
                                    className={classes.Timeframe}
                                    value={endDate}
                                    minDate={startDate}
                                    maxDate={add(startDate, {days: 89})}
                                    format={'dd.MM.yy'}
                                    onChange={handleEndDateChange}
                                />
                            </div>
                            <div className={classes.TotalImpression}>
                                <span>Total impressions:</span>
                                <strong>{totalImpression}</strong>
                            </div>
                            {/*<div className={classes.ClaimLimit}>*/}
                            {/*    <span>Claim limit:</span>*/}
                            {/*    <strong>{offer?.claimsMax ? offer.claimsMax + ' times' : 'Unlimited'} </strong>*/}
                            {/*</div>*/}
                            {/*<div className={classes.GenderFilter}>*/}
                            {/*    <span>Gender filter:</span>*/}
                            {/*    <strong>{gender}</strong>*/}
                            {/*</div>*/}
                        </div>
                        <div className={classes.ChartsContainer}>
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsTotal'}
                                title={'Total Impressions'}
                            />
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsInFeed'}
                                title={'Impressions in Feed'}
                            />
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsInProfile'}
                                title={'Impressions in Profile'}
                            />
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsInSearchResults'}
                                title={'Impressions in Search Results'}
                            />
                        </div>

                    </div>


                </div>
            </SwipeWrapper>
        </ModalOverlay>
    );
};

export default PostStats;