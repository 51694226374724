import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import classes from './OfferSchedule.module.scss'
import OfferScheduleStartEndTimeListItem from './OfferScheduleStartEndTimeListItem'
import OfferScheduleModeListItem from './OfferScheduleModeListItem'
import OfferSchedulePresets from './OfferSchedulePresets'
import OfferSchedulePresetsDropdown from './OfferSchedulePresetsDropdown'
import OfferScheduleCustom from './OfferScheduleCustom'
import {AnalyticsContext} from "../Shared/context/analyticsContext";
import {executeIfExist} from "../Shared/utility/utils";
import {add} from "date-fns";

const START_OF_DAY = 0

const NINE_HOUR_MINUTES = 540
const THREE_HOUR_MINUTES = 180
const _17_HOUR_MINUTES = 1020
const ALL_DAY_MINUTES = 1440



// available offer schedules
const PRESETS = [
    {
        name: 'Mon-Fri 09:00-18:00',
        hours: [
            {
                weekday: 0,
                startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
                durationMinutes: NINE_HOUR_MINUTES,
            },
            {
                weekday: 1,
                startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
                durationMinutes: NINE_HOUR_MINUTES,
            },
            {
                weekday: 2,
                startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
                durationMinutes: NINE_HOUR_MINUTES,
            },
            {
                weekday: 3,
                startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
                durationMinutes: NINE_HOUR_MINUTES,
            },
            {
                weekday: 4,
                startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
                durationMinutes: NINE_HOUR_MINUTES,
            },
        ],
    },
    {
        name: 'Mon-Fri 17:00-20:00',
        hours: [
            {
                weekday: 0,
                startTimeMinutesSinceMidnight:  _17_HOUR_MINUTES,
                durationMinutes: THREE_HOUR_MINUTES,
            },
            {
                weekday: 1,
                startTimeMinutesSinceMidnight:  _17_HOUR_MINUTES,
                durationMinutes: THREE_HOUR_MINUTES,
            },
            {
                weekday: 2,
                startTimeMinutesSinceMidnight:  _17_HOUR_MINUTES,
                durationMinutes: THREE_HOUR_MINUTES,
            },
            {
                weekday: 3,
                startTimeMinutesSinceMidnight:  _17_HOUR_MINUTES,
                durationMinutes: THREE_HOUR_MINUTES,
            },
            {
                weekday: 4,
                startTimeMinutesSinceMidnight:  _17_HOUR_MINUTES,
                durationMinutes: THREE_HOUR_MINUTES,
            },
        ],
    },
    {
        name: 'Fri-Sat 09:00-18:00',
        hours: [
            {
                weekday: 4,
                startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
                durationMinutes: NINE_HOUR_MINUTES,
            },
            {
                weekday: 5,
                startTimeMinutesSinceMidnight: NINE_HOUR_MINUTES,
                durationMinutes: NINE_HOUR_MINUTES,
            },
        ],
    },
]

const OFFER_HOURS_24X7 = [
    {
        weekday: 0,
        startTimeMinutesSinceMidnight: START_OF_DAY,
        durationMinutes: ALL_DAY_MINUTES,
    },
    {
        weekday: 1,
        startTimeMinutesSinceMidnight: START_OF_DAY,
        durationMinutes: ALL_DAY_MINUTES,
    },
    {
        weekday: 2,
        startTimeMinutesSinceMidnight: START_OF_DAY,
        durationMinutes: ALL_DAY_MINUTES,
    },
    {
        weekday: 3,
        startTimeMinutesSinceMidnight: START_OF_DAY,
        durationMinutes: ALL_DAY_MINUTES,
    },
    {
        weekday: 4,
        startTimeMinutesSinceMidnight: START_OF_DAY,
        durationMinutes: ALL_DAY_MINUTES,
    },
    {
        weekday: 5,
        startTimeMinutesSinceMidnight: START_OF_DAY,
        durationMinutes: ALL_DAY_MINUTES,
    },
    {
        weekday: 6,
        startTimeMinutesSinceMidnight: START_OF_DAY,
        durationMinutes: ALL_DAY_MINUTES,
    },
]

const OfferSchedule = (props) => {
    const analyticsEvents = useContext(AnalyticsContext)
    const dispatch = useDispatch()
    const {updateOffer, offer} = props

    const title = <span className={classes.Title}>{'Launch & Schedule'}</span>

    const handleStartTimeChange = (newVal) => {
        dispatch(
            updateOffer({
                startTime: newVal
            })
        )
    }

    const handleStartTimeClick = () => {
        executeIfExist(analyticsEvents?.timeframe_start_field_clicked)
    }

    const handleEndTimeChange = (newVal) => {
        dispatch(
            updateOffer({
                endTime: newVal,
            })
        )
    }

    const handleEndTimeClick = () => {
        executeIfExist(analyticsEvents?.timeframe_end_field_clicked)
    }

    const startEndTime = (
        <ul className={classes.StartEndTimeList}>
            <OfferScheduleStartEndTimeListItem
                title={'Start'}
                value={new Date(offer.startTime || Date.now())}
                maxDate={new Date(offer.endTime || add(new Date(), {years: 1}))}
                onChange={handleStartTimeChange}
                onClick={handleStartTimeClick}
            />
            <OfferScheduleStartEndTimeListItem
                title={'End'}
                value={new Date(offer.endTime || add(new Date(), {years: 1}))}
                minDate={new Date(offer.startTime || Date.now())}
                onChange={handleEndTimeChange}
                onClick={handleEndTimeClick}
            />
        </ul>
    )

    const [currentMode, setCurrentMode] = useState('none')

    const onModeSelected = (newMode) => {
        setCurrentMode(newMode)
        setPresetsDropdownVisible(false)
        switch (newMode) {
            case 'none':
                dispatch(
                    updateOffer({
                        hours: OFFER_HOURS_24X7,
                        customHours: []
                    })
                )
                break
            case 'preset':
                dispatch(
                    updateOffer({
                        hours: PRESETS[0].hours,
                        customHours: []
                    })
                )
                break
            case 'custom':
                dispatch(updateOffer({
                        hours: [],
                        customHours: []
                    })
                )
                break
            default:
                console.debug('wrong mode selected')
                break
        }
    }

    const handleNoScheduleClick = () => {
        executeIfExist(analyticsEvents?.no_schedule_btn_clicked)
        onModeSelected('none')
    }

    const handlePresetScheduleClick = () => {
        executeIfExist(analyticsEvents?.preset_schedule_btn_clicked)
        onModeSelected('preset')
    }


    const handleCustomScheduleClick = () => {
        executeIfExist(analyticsEvents?.custom_schedule_btn_clicked)
        onModeSelected('custom')
    }

    const mode = (
        <ul className={classes.ModeList}>
            <OfferScheduleModeListItem
                text="No schedule"
                onClick={handleNoScheduleClick}
                isSelected={currentMode === 'none'}
            />
            <OfferScheduleModeListItem
                text="Preset schedule"
                onClick={handlePresetScheduleClick}
                isSelected={currentMode === 'preset'}
            />
            <OfferScheduleModeListItem
                text="Custom schedule"
                onClick={handleCustomScheduleClick}
                isSelected={currentMode === 'custom'}
            />
        </ul>
    )
    // presets
    const [isPresetsDropdownVisible, setPresetsDropdownVisible] = useState(false)
    const handleDropdownPresetSelected = (preset) => {
        executeIfExist(analyticsEvents?.preset_schedule_dropdown_item_clicked)
        dispatch(updateOffer({
            hours: preset.hours
        }))
        setPresetsDropdownVisible(false)
        setCurrentMode('preset')
    }

    const presetsDropdown = isPresetsDropdownVisible ? (
        <OfferSchedulePresetsDropdown
            presets={PRESETS}
            onPresetSelected={handleDropdownPresetSelected}
        />
    ) : null

    const handleCurrentPresetClick = (props) => {
        executeIfExist( analyticsEvents?.preset_schedule_field_clicked)
        setPresetsDropdownVisible(!isPresetsDropdownVisible)
    }

    // compare current hours with presets and if a match is found, return the name of the matching preset
    const getPresetNameForCurrentOfferHours = useCallback(() => {
        for (let p of PRESETS) {
            if (!p.hours || !offer.hours) {
                continue
            }
            let hoursMatching = 0

            if (p.hours.length === offer.hours.length)
                for (const ph of p.hours) {
                    for (const oh of offer.hours) {
                        if (
                            ph.weekday === oh.weekday &&
                            ph.startTimeMinutesSinceMidnight === oh.startTimeMinutesSinceMidnight &&
                            ph.durationMinutes === oh.durationMinutes
                        ) {
                            hoursMatching++
                        }
                    }
                }

            if (hoursMatching === p.hours.length) {
                return p.name
            }
        }

        let hoursMatching = 0
        for (const ph of OFFER_HOURS_24X7) {
            if (!offer.hours) {
                continue
            }
            for (const oh of offer.hours) {
                if (
                    ph.weekday === oh.weekday &&
                    ph.startTimeMinutesSinceMidnight === oh.startTimeMinutesSinceMidnight &&
                    ph.durationMinutes === oh.durationMinutes
                ) {
                    hoursMatching++
                }
            }
        }
        if (hoursMatching === OFFER_HOURS_24X7.length) {
            return 'NO_SCHEDULE'
        }

        return 'Select from dropdown'
    }, [offer.hours])

    useEffect(() => {
        const presetName = getPresetNameForCurrentOfferHours()
        if (offer.hours) {
            if (presetName === 'Select from dropdown') {
                setCurrentMode('custom')
            } else if (presetName === 'NO_SCHEDULE') {
                setCurrentMode('none')
            } else {
                setCurrentMode('preset')
            }
        }
    }, [offer.hours, getPresetNameForCurrentOfferHours])


    useEffect(() => {
        if(!offer.hours){
            dispatch(
                updateOffer({
                    hours: OFFER_HOURS_24X7,
                })
            )
        }
    }, [dispatch, updateOffer, offer.hours])
    const presets =
        currentMode === 'preset' ? (
            <OfferSchedulePresets
                onClick={handleCurrentPresetClick}
                selectedPresetName={getPresetNameForCurrentOfferHours()}
            />
        ) : null

    const custom = currentMode === 'custom'
        ? <OfferScheduleCustom {...props}/>
        : null

    return (
        <div className={classes.CreateOfferSchedule}>
            {title}
            {startEndTime}
            {mode}
            {presets}
            {presetsDropdown}
            {custom}
        </div>
    )
}

export default OfferSchedule
