import React from "react";
import {MEDIA_BREAKPOINTS} from "../constants";

export const handleErrors = (errors) => {
    for (const error in errors) {
        if (errors[error]) return true
    }
    return false
}

export const validateNumberInput = (event) => {
    const numbersRegexp = /^[0-9\b]+$/
    return !event.target.value || numbersRegexp.test(event.target.value)
}

export const returnObservableItem = (arrLength, limit, index, setElement) => {
    if ((index === arrLength - limit && index !== 0) || (index + 1 === limit && limit === arrLength)) {
        return <div style={{backgroundColor: "red"}} ref={setElement}/>
    }
    return null
}

export const addHighlights = (str, search, className) => {
    const searchIndex = str.toLowerCase().indexOf(search.toLowerCase())
    const searchStringLength = search.length
    if (searchIndex >= 0) {
        return str.substring(0, searchIndex)
            + `<span class=${className}>`
            + str.substring(searchIndex, searchIndex + searchStringLength)
            + `</span>`
            + str.substring(searchIndex + searchStringLength)
    }
    return str
}

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const executeIfExist = (callback) => {
    if (typeof callback === 'function') {
        callback()
    }
}

export const getUsername = (user) => {
    let result = ''
    if (user?.firstname) result += user?.firstname + ' '
    if (user?.lastname) result += user?.lastname
    return result.trim()
}

export const mediaBreakpointDown = (breakpoint) => {
    if (!Object.values(MEDIA_BREAKPOINTS).includes(breakpoint)){
        return null
    }
    return `(max-width: ${breakpoint - 0.02}px)`
}