import {useCallback, useEffect, useState} from "react";

export const usePopperClickOutside = ({referenceElement, popperElement}) => {
   const [visible, setVisible] = useState(false)

    const handleDocumentClick = useCallback((event) => {
        if (referenceElement?.contains(event.target) || popperElement?.contains(event.target)) {
            return;
        }
        setVisible(false);
    }, [referenceElement, popperElement])

    useEffect(() => {
        document.addEventListener("mousedown", handleDocumentClick);
        return () => {
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, [handleDocumentClick]);

    const toggleVisible = () => {
        setVisible(!visible)
    }

    return {visible, toggleVisible}
}