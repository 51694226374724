import React from 'react'
import classes from './OfferScheduleStartEndTimeListItem.module.css'
import DatePicker from "../Components/Date/DatePicker/DatePicker";

const OfferScheduleStartEndTimeListItem = (props) => {
    return (
        <li className={classes.Container}>
            <span className={classes.Title}>{props.title}</span>
            <DatePicker
                className={classes.DatePicker}
                value={props.value}
                onChange={props.onChange}
                onClick={props.onClick}
                minDate={props.minDate}
                maxDate={props.maxDate}
            />
        </li>
    )
}

export default OfferScheduleStartEndTimeListItem
