import React, {useEffect, useState} from 'react';
import ChannelSettings from "@sendbird/uikit-react/ChannelSettings";
import ReactDOM from "react-dom";
import CustomInviteUsersModal from "../CustomInviteUsersModal/CustomInviteUsersModal";

const ChannelSettingsWrapper = ({channel, isShow, onClose}) => {
    const [dynamicElement, setDynamicElement] = useState(null)
    const [isRerender, setIsRerender] = useState(true)
    const [members, setMembers] = useState(null)

    useEffect(() => {
        const handler = (event) => {
            if (event.target.textContent === 'Invite users') {
                const body = document.querySelector('.sendbird-modal__body')
                body.innerHTML = '';
                setDynamicElement(body)
                const members = document.querySelector('#members') || document.querySelector('.sendbird-channel-settings__panel-item.sendbird-channel-settings__members')
                setMembers(members)
            }
        }
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler)
        }
    }, [])

    useEffect(() => {
        setIsRerender(true)
        members?.click()
    // eslint-disable-next-line
    }, [isRerender])

    const handleForceUpdate = (newMembersCount) => {
        setIsRerender(false)
        if (members) {
            const badge = members.querySelector('.sendbird-badge span')
            badge.textContent = +badge.textContent + newMembersCount
        }
    }

    return (
        <>
            {
                isShow &&
                <ChannelSettings
                    channelUrl={channel?.url ?? null}
                    onCloseClick={onClose}
                />
            }
            {
                dynamicElement &&
                ReactDOM.createPortal(
                    <CustomInviteUsersModal
                        channel={channel}
                        forceUpdate={handleForceUpdate}
                    />,
                    dynamicElement
                )
            }
        </>
    );
};

export default ChannelSettingsWrapper;