import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {
    resetSearchParams,
    searchParamsSelector,
    setInitialSearchParams
} from "../reducers/searchParamsReducer";
import {useDebounceFunction} from "./useDebounceFunction";

const useFetchWithQueryParams = ({initialValue, currentPage, callback = () => {},  thunks}) => {
    const dispatch = useDispatch()
    const params = useSelector(searchParamsSelector)

    const debounceUpdateQuery = useDebounceFunction(callback)

    useEffect(() => {
        dispatch(setInitialSearchParams({...initialValue, currentPage}))
        return () => {
            dispatch(resetSearchParams())
        }
        // eslint-disable-next-line
    }, []);

    const isReady = Object.keys(params).length !== 0 && params.currentPage === currentPage

    useEffect(() => {
        if (isReady && thunks) {
            thunks.forEach((thunk) => {
                dispatch(thunk())
            })
        }
    }, [dispatch, params, isReady, thunks])

    return [params, isReady, debounceUpdateQuery]
}

export {useFetchWithQueryParams}