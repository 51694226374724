import React, {useRef} from 'react';
import {ReactComponent as TimesIcon} from "../../Shared/UI/assets/closeIcon.svg";
import classes from './ModalOverlay.module.scss'
import useMediaQuery from "../../Shared/hooks/useMediaQueries";
import {mediaBreakpointDown} from "../../Shared/utility/utils";
import {MEDIA_BREAKPOINTS} from "../../Shared/constants";

const ModalOverlay = ({onClose, children}) => {
    const ref = useRef()

    const matches = useMediaQuery(mediaBreakpointDown(MEDIA_BREAKPOINTS.SM))

    const handleCloseOverlay = (event) => {
        if (ref.current === event.target && matches) onClose()
    }

    return (
        <>
            <div ref={ref} className={classes.OverlayContainer} onClick={handleCloseOverlay}/>
            <div className={classes.Container}>
                <div className={classes.CloseIcon}>
                    <TimesIcon
                        width={'16px'}
                        height={'16px'}
                        onClick={onClose}
                    />
                </div>
                {children}
            </div>
        </>
    );
};

export default ModalOverlay;