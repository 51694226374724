import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    searchParams: {}
}

const  searchParamsReducer = createSlice(
    {
        name:'searchParams',
        initialState,
        reducers: {
            setSearchParams: (state, action) => {
                state.searchParams = {...state.searchParams, ...action.payload}
            },
            resetSearchParams: (state) => {
                state.searchParams = {}
            },
            setInitialSearchParams: (state, action) => {
                state.searchParams = action.payload
            }
        }
    }
)

export default searchParamsReducer.reducer

export const {
    resetSearchParams,
    setSearchParams,
    setInitialSearchParams,
} = searchParamsReducer.actions

export const searchParamsSelector = (state) => state.searchParamsReducer.searchParams