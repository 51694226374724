import React from 'react';
import classes from "./MoreItem.module.scss";

const MoreItem = ({text, onClick, danger}) => {
    return (
        <div
            className={`${classes.MoreItem} ${danger ? classes.Danger : ''}`}
            onClick={onClick}
        >
            <span>{text}</span>
        </div>
    );
};

export default MoreItem;