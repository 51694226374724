import React from 'react';
import classes from "./Slider.module.scss";
import {ReactComponent as Back} from "../../Shared/UI/assets/back.svg";
import {useSwiper} from "swiper/react";

const SlidePrevButton = () => {
    const swiper = useSwiper();
    const handlePrevButtonClick = () => {
        swiper.slidePrev()
    }
    return (
        <div className={classes.PrevButton} onClick={handlePrevButtonClick}>
            <Back className={classes.Icon} fill={'#303141'} />
        </div>
    );
};

export default SlidePrevButton;