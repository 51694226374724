import React from 'react'
import classes from './Buttons.module.scss'
import {ReactComponent as  PlusIcon} from "../../Shared/UI/assets/plus.svg";
import {ReactComponent as  BackIcon} from "../../Shared/UI/assets/back-button-icon.svg";

export const BUTTON_TYPES = {
    SECONDARY: 'Secondary',
    TEXT: 'Text',
    NOT_ROUNDED: 'NOT_ROUNDED',
    NOT_ROUNDED_SECONDARY: 'NOT_ROUNDED_SECONDARY',
}

export const Button = ({className, type, variant, onClick, disabled, children, ...rest}) => {
    let cls = [classes.Button_v2]
    switch (variant) {
        case BUTTON_TYPES.SECONDARY :
            cls.push(classes.SecondaryButton_v2)
            break
        case BUTTON_TYPES.TEXT :
            cls.push(classes.TextButton)
            break
        case BUTTON_TYPES.NOT_ROUNDED :
            cls.push(classes.NotRounded)
            break
        case BUTTON_TYPES.NOT_ROUNDED_SECONDARY :
            cls.push(classes.NotRounded, classes.SecondaryButton_v2)
            break
        default :
            cls.push(classes.Button_v2)
    }
    if (className) cls.push(className)
    return (
        <button
            className={cls.join(' ')}
            type={type}
            onClick={onClick}
            disabled={disabled}
            {...rest}
        >
            {children}
        </button>
    )
}

export const TextButton = ({className, onClick, disabled, children, ...rest}) => {
    return (
        <Button className={className} variant={BUTTON_TYPES.TEXT} onClick={onClick} disabled={disabled} {...rest}>
             {children}
        </Button>
    )
}

export const PlusButton = ({className, children, ...rest}) => {
    return (
        <Button className={`${classes.PlusButton} ${className}`} variant={BUTTON_TYPES.TEXT} {...rest}>
            <PlusIcon className={classes.PlusIcon}/> {children}
        </Button>
    )
}

export const BackButton = ({className, children, ...rest}) => {
    return (
        <Button className={className} variant={BUTTON_TYPES.TEXT} {...rest}>
            <BackIcon className={classes.BackIcon}/> {children}
        </Button>
    )
}