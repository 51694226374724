import React from 'react';
import classes from './Switch.module.scss'

const Switch = ({name, onChange, checked, ...rest }) => {
    return (
        <label className={classes.Container} {...rest}>
            <input name={name} onChange={onChange} checked={checked} type="checkbox"/>
            <span/>
        </label>
    );
};

export default Switch;
