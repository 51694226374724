import React, {useEffect} from 'react';
import classes from './Message.module.css'

const Message = ({className, message, handler, type, children}) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            handler()
        }, 5000)
        return () => {
            clearTimeout(timer)
        }
    }, [handler])

    const cls = [className, classes.MessageContainer, classes.Message ]
    switch (type) {
        case MESSAGES_TYPES.ERROR:
            cls.push(classes.Error)
            break
        case MESSAGES_TYPES.SUCCESS:
            cls.push(classes.Success)
            break
        case MESSAGES_TYPES.WARNING:
            cls.push(classes.Warning)
            break
        default :
            cls.push(classes.Success)
    }

    return (
        <div className={cls.join(' ')}>
            <span>{message}</span>
            {children}
        </div>
    );
};

export const MESSAGES_TYPES = {
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
}

export const createMessage = ({message, type, className, children}) => ({
    message,
    type,
    className,
    children
})


export default Message
