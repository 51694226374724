import React, {useState} from 'react'
import classes from './Sidebar.module.scss'
import useMediaQuery from "../../Shared/hooks/useMediaQueries";
import {mediaBreakpointDown} from "../../Shared/utility/utils";
import {MEDIA_BREAKPOINTS} from "../../Shared/constants";
import Burger from "./UI/Burger/Burger";

const Sidebar = ({children}) => {
    const [isOpen, setIsOpen] = useState(false)

    const matches = useMediaQuery(mediaBreakpointDown(MEDIA_BREAKPOINTS.LG))

    const handleBurgerClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            {
                matches &&
                <Burger
                    className={classes.Burger}
                    isActive={isOpen}
                    onClick={handleBurgerClick}
                />
            }
            <div className={`${classes.Sidebar} ${isOpen ? classes.Active : ''}`}>
                {
                    React.Children.map(children, child => {
                        if (child) {
                            return React.cloneElement(child, {
                                onClick: (event) => {
                                    event.stopPropagation()
                                    if (child.props.onClick){
                                        child.props.onClick(event)
                                        setIsOpen(false)
                                    }
                                },
                            })
                        }
                    })
                }
            </div>
        </>
    )
}

export default Sidebar
