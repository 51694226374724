import React, {useEffect, useMemo, useState} from 'react';
import classes from "./OfferStats.module.scss";
import ModalOverlay from "../../Components/ModalOverlay/ModalOverlay";
import {useDispatch} from "react-redux";
import Chart from "../../Components/Chart/Chart";
import OfferPreview from "../OfferPreview";
import {add, sub} from "date-fns";
import DatePicker from "../../Components/Date/DatePicker/DatePicker";
import SwipeWrapper from "../../Components/Draggable/SwipeWrapper";

const OfferStats = ({onClose, offer, stats, getOfferStatsThunk }) => {
    const dispatch = useDispatch()

    const [startDate, setStartDate] = useState(sub(new Date(),{days: 7}))
    const [endDate, setEndDate] = useState(new Date())

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }

    const handleEndDateChange = (date) => {
        setEndDate(date)
    }

    let gender = null

    switch (offer?.tags?.[0]?.id) {
        case 'male_only' :
            gender = 'Male only'
            break
        case 'female_only' :
            gender = 'Female only'
            break
        default :
            gender = 'Both'
    }

    const totalImpression = useMemo(()=>{
       return  stats?.reduce((total, current)=> total + current.impressionsTotal ,0)
    } ,[stats])

    useEffect(() => {
        dispatch(getOfferStatsThunk({
            id: offer.id,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString()
        }))
    }, [dispatch, startDate, endDate, offer.id, getOfferStatsThunk])

    return (
        <ModalOverlay onClose={onClose}>
            <SwipeWrapper handler={onClose}>
                <div className={classes.Container}>
                    <div className={classes.PreviewContainer}>
                        <OfferPreview
                            offer={offer}
                        />
                    </div>
                    <div className={classes.StatisticsContainer}>
                        <div className={classes.Header}>
                            <div className={classes.TimeframeStart}>
                                <div className={classes.Label}>
                                    <span>Timeframe start:</span>
                                </div>
                                <DatePicker
                                    className={classes.Timeframe}
                                    value={startDate}
                                    minDate={sub(endDate, {days: 89})}
                                    maxDate={endDate}
                                    format={'dd.MM.yy'}
                                    onChange={handleStartDateChange}
                                />
                            </div>
                            <div className={classes.TimeframeEnd}>
                                <div className={classes.Label}>
                                    <span>Timeframe end:</span>
                                </div>
                                <DatePicker
                                    className={classes.Timeframe}
                                    value={endDate}
                                    minDate={startDate}
                                    maxDate={add(startDate, {days: 89})}
                                    format={'dd.MM.yy'}
                                    onChange={handleEndDateChange}
                                />
                            </div>
                            <div className={classes.ClaimLimit}>
                                <span>Claim limit:</span>
                                <strong>{offer?.claimsLimit ? offer.claimsLimit + ' times' : 'Unlimited'} </strong>
                            </div>
                            <div style={{height:0,flexBasis:'100%'}}/>
                            <div className={classes.GenderFilter}>
                                <span>Gender filter:</span>
                                <strong>{gender}</strong>
                            </div>
                            <div className={classes.TotalImpression}>
                                <span>Total impressions:</span>
                                <strong>{totalImpression}</strong>
                            </div>
                        </div>
                        <div className={classes.ChartsContainer}>
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsTotal'}
                                title={'Total Impressions'}
                            />
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsInFeed'}
                                title={'Impressions in Feed'}
                            />
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsInProfile'}
                                title={'Impressions in Profile'}
                            />
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsInSearchResults'}
                                title={'Impressions in Search Results'}
                            />
                            <Chart
                                data={stats}
                                XAxisDataKey={'labelDate'}
                                valueDataKey={'impressionsOnMap'}
                                title={'Impressions on Map'}
                            />
                        </div>

                    </div>


                </div>
            </SwipeWrapper>
        </ModalOverlay>

    );
};

export default OfferStats;