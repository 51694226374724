import React, {useContext} from 'react'

import {useDispatch} from 'react-redux'

import classes from './OfferSettings.module.scss'

import OfferSettingsClaimLimitOptionsList from './OfferSettingsClaimLimitOptionsList'
import CheckboxSelectedIcon from '../Shared/UI/assets/CheckBoxSelected.svg'
import CheckboxNotSelectedIcon from '../Shared/UI/assets/CheckBoxNotSelected.svg'
import Input from "../Components/Input/Input";
import {GeocodeApiInstance} from "../Shared/utility/getCoordinatesFromAddress";
import Map from "../Components/Map/Map";
import {ReactComponent as NaooIcon} from "../Shared/UI/assets/naooIcon.svg";
import {executeIfExist, validateNumberInput} from "../Shared/utility/utils";
import {AnalyticsContext} from "../Shared/context/analyticsContext";


const OfferSettings = (props) => {
    const dispatch = useDispatch()
    const analyticsEvents = useContext(AnalyticsContext)
    const genderFilter = (props.offer.tags && props.offer.tags.length!==0) ? props.offer?.tags[0]?.id : 'both'

    const onOfferMaxClaimsSelected = (newValue) => {
        console.debug('onOfferMaxClaimsSelected: ', newValue)
        dispatch(
            props.updateOffer({
                claimsLimit: parseInt(newValue),
            })
        )
    }

    const handleClaimOnlyOnceClick = () => {
        executeIfExist(analyticsEvents?.claiming_allow_only_once_btn_clicked)
        dispatch(props.updateOffer({claimsPerUserLimit: props.offer.claimsPerUserLimit ? null : 1}))
    }

    const claimsLimitOptions = (
        <OfferSettingsClaimLimitOptionsList
            claimsLimit={props.offer.claimsLimit}
            onOfferMaxClaimsSelected={onOfferMaxClaimsSelected}
        />
    )

    const claimOnlyOnce = (
        <div className={classes.ClaimOnlyOnceContainer} onClick={handleClaimOnlyOnceClick}>
            <img
                alt=""
                className={classes.ClaimOnlyOnceCheckbox}
                src={
                    props.offer.claimsPerUserLimit
                        ? CheckboxSelectedIcon
                        : CheckboxNotSelectedIcon
                }
            />
            <span className={classes.ClaimOnlyOnceLabel}>
        {'Allow the customer to claim the offer only once'}
      </span>
        </div>
    )

    const handleAddressChange = async (event) => {
        dispatch(
            props.updateOffer({
                address: event.target.value,
            })
        )
        // dispatch(setAddress(event.target.value))
        try {
            const coordinates = await GeocodeApiInstance.getCoordinatesFromAddress(event.target.value)
            dispatch(
                props.updateOffer({
                    coordinates
                })
            )
        }
        catch (e){
            console.log(e)
        }


    }

    const handleClickIntoMap = (address, lat, lng) => {
        dispatch(
            props.updateOffer({
                address: address.formatted_address,
                coordinates: [lat, lng],
            })
        )
    }
    const handleClaimLocationClick =  () => {
        executeIfExist(analyticsEvents?.claiming_allow_claiming_at_location_btn_clicked)
        dispatch(props.updateOffer({
            canBeClaimedAtLocation: !props.offer.canBeClaimedAtLocation
        }))
    }

    const claimLocation = (
        <div>
            <div className={classes.ClaimLocationContainer} onClick={handleClaimLocationClick}>
                <img
                    alt=""
                    className={classes.ClaimOnlyOnceCheckbox}
                    src={
                        props.offer.canBeClaimedAtLocation
                            ? CheckboxSelectedIcon
                            : CheckboxNotSelectedIcon
                    }
                />
                <span>Can be claimed at location</span>
            </div>

            {
                props.offer.canBeClaimedAtLocation &&
                <>
                    <Input
                        type={'text'}
                        onChange={handleAddressChange}
                        placeholder={'Address'}
                        className={classes.AddressInput}
                        value={props.offer.address}
                    />

                    <div className={classes.MapContainer}>
                        <Map
                            lat={props.offer?.coordinates?.[0] ||  47.3666700}
                            lng={props.offer?.coordinates?.[1] || 8.55}
                            defaultZoom={15}
                            onClick={handleClickIntoMap}
                        />
                    </div>
                </>
            }
        </div>
    )
    // gender filter
    const handleGenderFilterSelected = (val) => {
        console.debug('gender filter selected: ', val)
        //setGenderFilter(val)
        // update tags
        switch (val) {
            case 'm':
                dispatch(
                    props.updateOffer({
                        tags: [{id: 'male_only'}],
                    })
                )
                break
            case 'f':
                dispatch(
                    props.updateOffer({
                        tags: [{id: 'female_only'}],
                    })
                )
                break
            default:
                dispatch(
                    props.updateOffer({
                        tags: [],
                    })
                )
                break
        }
    }

    const handleBothClick = () =>{
        executeIfExist(analyticsEvents?.gender_filter_male_btn_clicked)
        handleGenderFilterSelected('both')
    }

    const handleMaleClick = () =>{
        executeIfExist(analyticsEvents?.gender_filter_female_btn_clicked)
        handleGenderFilterSelected('m')
    }

    const handleFemaleClick = () =>{
        executeIfExist(analyticsEvents?.gender_filter_both_btn_clicked)
        handleGenderFilterSelected('f')
    }

    const genderOptions = (
        <div className={classes.GenderFilterContainer}>
            <span className={classes.GenderFilterLabel}>{'Gender filter'}</span>
            <ul className={classes.GenderFilterList}>
                <li
                    className={classes.GenderFilterListItem}
                    style={{
                        background: genderFilter === 'both' ? '#3C49C6' : '#FFFFFF',
                        color: genderFilter === 'both' ? '#FFFFFF' : '#303141',
                    }}
                    onClick={handleBothClick}
                >
                    {'Both'}
                </li>
                <li
                    className={classes.GenderFilterListItem}
                    style={{
                        background: genderFilter === 'male_only' ? '#3C49C6' : '#FFFFFF',
                        color: genderFilter === 'male_only' ? '#FFFFFF' : '#303141',
                    }}
                    onClick={handleMaleClick}
                >
                    {'Male'}
                </li>
                <li
                    className={classes.GenderFilterListItem}
                    style={{
                        background: genderFilter === 'female_only' ? '#3C49C6' : '#FFFFFF',
                        color: genderFilter === 'female_only' ? '#FFFFFF' : '#303141',
                    }}
                    onClick={handleFemaleClick}
                >
                    {'Female'}
                </li>
            </ul>
        </div>
    )

    const handlePointsChange = (event) => {
        const isValidInput = validateNumberInput(event)
        if (isValidInput){
            dispatch(
                props.updateOffer({
                    points: event.target.value,
                })
            )
        }
    }

    const pointsAmount = (
        props.offer.isAdminDashboard
            ? <Input
                label={'Points amount'}
                placeholder={'Points'}
                Icon={NaooIcon}
                onChange={handlePointsChange}
                value={props.offer.points}
            />
            : null
    )

    return (
        <div className={classes.Container}>
            <span className={classes.Title}>{'Settings'}</span>
            <span className={classes.Subtitle}>{'Claiming'}</span>
            {pointsAmount}
            <span className={classes.ClaimLimitTitle}>{'Claim limit'}</span>
            {claimsLimitOptions}
            {claimOnlyOnce}
            {claimLocation}
            <span className={classes.Subtitle}>{'Offer'}</span>
            {genderOptions}
        </div>
    )
}

export default OfferSettings
