import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./StyledPicker.module.scss";

const StyledPicker = ({...rest}) => {
    const [isOpen, setIsOpen] = useState(false)


    return (
        <DatePicker
            open={isOpen}
            onInputClick={() => setIsOpen(!isOpen)}
            onCalendarClose={() => setIsOpen(false)}
            calendarClassName={classes.DatepickerCalendar}
            className={classes.DateTimePickerInput}
            clearButtonClassName={classes.ClearIcon}
            useWeekdaysShort={true}
            renderDayContents={(day) => <span>{day}</span>}
            disabledKeyboardNavigation
            {...rest}
        />
    );
};

export default StyledPicker;