export const AWS_REGION = process.env.REACT_APP_AWS_REGION

export const FIREHOSE_VARIABLES = {
    STREAM_NAME: process.env.REACT_APP_FIREHOSE_STREAM_NAME ?? 'naoo-kinesis-ml-events'
}

export const FIREBASE_VARIABLES = {
    API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
    DOMAIN_URI_PREFIX: process.env.REACT_APP_DOMAIN_URI_PREFIX,
    ANDROID_BUNDLE_ID: process.env.REACT_APP_ANDROID_BUNDLE_ID,
    IOS_BUNDLE_ID: process.env.REACT_APP_IOS_BUNDLE_ID,
    FALLBACK_URL: process.env.REACT_APP_FALLBACK_URL,
}

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyCWK7AD2Ikh-a27xMaVNMOzOKZwRAm1LIc'
