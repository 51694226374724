import React, {useEffect, useState} from 'react';
import CreateChannelUI from "@sendbird/uikit-react/CreateChannel/components/CreateChannelUI";
import {useCreateChannelContext} from "@sendbird/uikit-react/CreateChannel/context";
import ReactDOM from 'react-dom';
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {ReactComponent as AddIcon} from '../ChannelsListHeader/icon-create.svg'

import classes from '../ChannelsListHeader/ChannelsListHeader.module.scss'

const CustomCreateChannel = ({search, setSearch, isRerender, setIsRerender}) => {
    const [flag, setFlag] = useState(false)

    const [dynamicElement, setDynamicElement] = useState(null)

    const createChannelProps = useCreateChannelContext();
    const {step} = createChannelProps


    useEffect(() => {
        if (step === 1) {
            const modal = document.querySelector('.sendbird-modal__header')
            setDynamicElement(modal)
        }
    }, [step, isRerender])

    const handleSearchChange = (event) => {
        setSearch(event.target.value)
    }

    useEffect(() => {
        setIsRerender(false)
    }, [isRerender, setIsRerender])

    const handleAddIconClick = () => {
        setFlag(true)
    }

    return (
        <>
            <div className={classes.AddChannel}>
                <AddIcon
                    onClick={handleAddIconClick}
                />
            </div>
            {
                (flag && !isRerender) && <CreateChannelUI
                    onCancel={() => {
                        setFlag(false)
                        setSearch('')
                    }}
                />
            }
            {
                dynamicElement &&

                ReactDOM.createPortal(
                    <div className={classes.InputContainer}>
                        <Input
                            autoFocus
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </div>,
                    dynamicElement
                )
            }
        </>
    );
};

export default CustomCreateChannel;