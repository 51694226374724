import React from 'react';
import classes from "./EditBusiness.module.scss";

const Contacts = (
    {
        business, errors,
        handleWebsiteChange,handlePhoneChange,handleEmailChange
    }) => {
    return (
        <div className={classes.Section}>
            <span className={classes.SubTitle}>Contacts</span>
            <label>Website</label>
            <div className={classes.NameContainer}>
                <input
                    className={`${classes.Input} ${errors.website && classes.InputError}`}
                    name={'website'}
                    type="text"
                    placeholder={'Your website link'}
                    value={business.website}
                    onChange={handleWebsiteChange}
                    maxLength={255}
                />
                <span className={classes.NameLength}>{business?.website?.length}/255</span>
            </div>
            <label>Phone number</label>
            <div className={classes.NameContainer}>
                <input
                    className={`${classes.Input} ${errors.phone && classes.InputError}`}
                    name={'phone'}
                    type="phone"
                    placeholder={'Enter your phone number'}
                    value={business.phone}
                    onChange={handlePhoneChange}
                    maxLength={64}
                />
                <span className={classes.NameLength}>{business?.phone?.length}/64</span>
            </div>

            <label>Email</label>
            <div className={classes.NameContainer}>
                <input
                    className={`${classes.Input} ${errors.email && classes.InputError}`}
                    name={'email'}
                    type="text"
                    placeholder={'Enter your email'}
                    value={business.email}
                    onChange={handleEmailChange}
                    maxLength={512}
                />
                <span className={classes.NameLength}>{business?.email?.length}/512</span>
            </div>
        </div>
    );
};

export default Contacts;