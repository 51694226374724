import {useState} from 'react';
import {useSelector} from "react-redux";
import {
    fetchTagsByNextToken,
    selectFetching,
    selectNextToken,
    setFetching
} from "../../../../store/reducers/tagsReducer";
import {useObserver} from "../../Shared/hooks/useObserver";

export const useTagPagination = () => {
    const [element, setElement] = useState(null)
    const [rootElement, setRootElement] = useState(null)
    const limit = 10

    const nextToken = useSelector(state=>selectNextToken(state))
    const fetching = useSelector(state => selectFetching(state))

    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchTagsByNextToken,
        rootElement
    )
return [setElement, setRootElement, limit, fetching]
};